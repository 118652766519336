import { useContext } from 'react';
import { Stack, Typography } from '@mui/material';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { nullUndefinedOrEmpty } from '../../util/string';
import TextField from '../general/TextField';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import { TJobTitle } from '../../types/MetaTypes';

interface Props extends MetaFieldProps {
    showEditing?: boolean;
    onEdit?: () => void;
}

const JobTitle = ({ metaField, showEditing, onEdit }: Props) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TJobTitle;

    if (!content || nullUndefinedOrEmpty(content?.jobTitle)) {
        return null;
    }

    return (
        <Stack
            direction="column"
            spacing={0}
            sx={{
                // TODO: Here or on the parent?
                px: 3,
            }}
        >
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '24px',
                    fontWeight: 400,
                    lineHeight: '24px',
                    letterSpacing: '-0.02em',
                    textAlign: 'left',
                    wordBreak: 'break-word',
                    opacity: 0.8,
                    mt: 1,
                }}
            >
                {nullUndefinedOrEmpty(content.jobTitle) ? '' : content.jobTitle}
            </Typography>
        </Stack>
    );
};

const JobTitleForm = ({ metaField, onChange }: MetaFieldFormProps) => {
    const content = metaField?.metaContent as TJobTitle | undefined;

    const handleChange = (field: { name: string; value: string }) => {
        onChange?.({
            ...content,
            [field.name]: field.value,
        });
    };

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                // TODO: Here or on the parent?
                px: 2,
            }}
        >
            <TextField
                label="Job Title"
                placeholder="Job Title"
                value={content?.jobTitle ?? ''}
                onChange={value => handleChange({ name: 'jobTitle', value })}
            />
        </Stack>
    );
};

export { JobTitle, JobTitleForm };
