import axios, { AxiosResponse } from 'axios';
import { convertToJpeg } from 'src/util/image';

interface Line {
    text: string;
    boundingPolygon: { x: number; y: number }[];
}

interface Block {
    lines: Line[];
}

interface ReadResult {
    blocks: Block[];
}

interface AzureVisionResponse {
    readResult?: ReadResult;
}

interface AzureSearchResponse {
    ircodeID?: string;
}

interface TextAndScore {
    text: string;
    area: number;
}

export interface TOcr {
    analyze: ({ file }: { file: File }) => Promise<string[]>;
    search: (terms: string[]) => Promise<string | undefined>;
}

const area = (rect: { x: number; y: number }[]): number => {
    const [a, b, c, d] = rect;
    return Math.abs(
        (a.x * b.y + b.x * c.y + c.x * d.y + d.x * a.y - b.x * a.y - c.x * b.y - d.x * c.y - a.x * d.y) / 2,
    );
};

const filterBelowStandardDeviation = (data: TextAndScore[], deviationThreshold: number = 1): TextAndScore[] => {
    if (data.length === 0) return [];

    // Step 1: Calculate the mean of the area
    const mean = data.reduce((sum, item) => sum + item.area, 0) / data.length;

    // Step 2: Calculate the standard deviation of the area
    const variance = data.reduce((sum, item) => sum + Math.pow(item.area - mean, 2), 0) / data.length;
    const standardDeviation = Math.sqrt(variance);

    // Step 3: Calculate the threshold (mean - deviationThreshold * standard deviation)
    const threshold = mean - deviationThreshold * standardDeviation;

    // Step 4: Filter out items whose area is below the threshold
    return data.filter(item => item.area >= threshold);
};

const useOcr = (): TOcr => {
    const analyzeApi = axios.create({
        baseURL: process.env.REACT_APP_OCR_ANALYZE_API,
        headers: {
            'Content-Type': 'application/octet-stream',
            'Ocp-Apim-Subscription-Key': process.env.REACT_APP_OCR_ANALYZE_KEY,
        },
    });

    const analyze = async ({ file }: { file: File }): Promise<string[]> => {
        const reader = new FileReader();
        reader.readAsArrayBuffer(await convertToJpeg(file));

        const event = await new Promise<ProgressEvent<FileReader>>((resolve, reject) => {
            reader.onloadend = resolve;
            reader.onerror = reject;
        });

        if (!event?.target) {
            return [];
        }

        const response: AxiosResponse<AzureVisionResponse> = await analyzeApi.post('', event.target.result);

        // return (response.data.readResult?.blocks ?? []).reduce((acc, block) => {
        //     acc.push(
        //         ...block.lines.reduce((acc, line) => {
        //             acc.push(line.text);
        //             return acc;
        //         }, [] as string[]),
        //     );
        //     return acc;
        // }, [] as string[]);

        // This code attempts to remove unimportant text
        return (response.data.readResult?.blocks ?? []).reduce((acc, block) => {
            const textAndScores = block.lines.reduce((acc, line) => {
                acc.push({ text: line.text, area: area(line.boundingPolygon) });

                return acc;
            }, [] as TextAndScore[]);
            acc.push(...filterBelowStandardDeviation(textAndScores, 2).map(item => item.text));
            return acc;
        }, [] as string[]);
    };

    const searchApi = axios.create({
        baseURL: process.env.REACT_APP_OCR_SEARCH_API,
        headers: {
            'Content-Type': 'application/json',
            'x-functions-key': process.env.REACT_APP_OCR_SEARCH_KEY,
        },
    });

    const search = async (terms: string[]): Promise<string | undefined> => {
        try {
            const response: AxiosResponse<AzureSearchResponse> = await searchApi.post('', {
                index: process.env.REACT_APP_CLIENT,
                search: terms.join(' '),
            });
            // console.log('response', response);

            return response.data.ircodeID;
        } catch (error) {
            console.error(error);
            return undefined;
        }
    };

    return { analyze, search };
};

export default useOcr;
