import { useContext } from 'react';
import { getAuth } from 'firebase/auth';
import axios, { AxiosResponse } from 'axios';
import Cookies from 'js-cookie';
import EnvContext, { TEnv } from 'src/contexts/EnvContext';

export const apiBaseURL = `${process.env.REACT_APP_API}/${process.env.REACT_APP_ENV}-${process.env.REACT_APP_VERSION}`;

const api = axios.create({
    baseURL: apiBaseURL,
});

export enum Method {
    GET = 'GET',
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    PATCH = 'PATCH',
}

export interface RequestParams {
    method: Method;
    path: string;
    headers?: Record<string, string>;
    axiosParams?: Record<string, any>;
    data?: any;
    onProgress?: (progress: number) => void;
    abortController?: AbortController;
    useSdkApiKey?: boolean;
}

export interface TApi {
    request: (params: RequestParams) => Promise<AxiosResponse>;
}

const useApi = (): TApi => {
    const auth = getAuth();

    const { sdkapikey, impersonated } = useContext(EnvContext) as TEnv;

    const request = async ({
        method,
        path,
        headers = {},
        axiosParams,
        data,
        onProgress,
        abortController,
        useSdkApiKey = true,
    }: RequestParams): Promise<AxiosResponse> => {
        let token = await auth.currentUser?.getIdToken();

        const useCookie = Cookies.get('useCookie');
        if (useCookie) {
            token = useCookie;
        }

        headers = {
            'Content-Type': 'application/json',
            ...headers,
            // sdkapikey takes precedence over token
            ...(sdkapikey && useSdkApiKey ? { sdkapikey } : {}),
            ...(token ? { auth: token } : {}),
            ...(impersonated && impersonated.userID ? { impersonate: String(impersonated.userID) } : {}),
            forceadd: 'true',
        };

        switch (method) {
            case Method.GET:
                return api.get(path, {
                    headers,
                    onUploadProgress: progressEvent => {
                        onProgress?.(progressEvent.progress ?? 0);
                    },
                    signal: abortController?.signal,
                    ...axiosParams,
                });
            case Method.POST:
                return api.post(path, data, {
                    headers,
                    onUploadProgress: progressEvent => {
                        // console.log('progressEvent.progress', progressEvent.progress);
                        onProgress?.(progressEvent.progress ?? 0);
                    },
                    onDownloadProgress: progressEvent => {
                        // console.log('progressEvent.progress', progressEvent.progress);
                        onProgress?.(progressEvent.progress ?? 0);
                    },
                    signal: abortController?.signal,
                    ...axiosParams,
                });
            case Method.PUT:
                return api.put(path, data, {
                    headers,
                    onUploadProgress: progressEvent => {
                        onProgress?.(progressEvent.progress ?? 0);
                    },
                    signal: abortController?.signal,
                    ...axiosParams,
                });
            case Method.DELETE:
                return api.delete(path, {
                    headers,
                    data,
                    onUploadProgress: progressEvent => {
                        onProgress?.(progressEvent.progress ?? 0);
                    },
                    signal: abortController?.signal,
                    ...axiosParams,
                });
            case Method.PATCH:
                return api.patch(path, data, {
                    headers,
                    onUploadProgress: progressEvent => {
                        onProgress?.(progressEvent.progress ?? 0);
                    },
                    signal: abortController?.signal,
                    ...axiosParams,
                });
            default:
                throw new Error(`Unsupported method: ${method}`);
        }
    };

    return { request };
};

export default useApi;
