import React, { useEffect } from 'react';
import { Alert, Box, Button, Fade, Link, Stack, Typography } from '@mui/material';
import UserContext, { SignInResult, SignInStatus, TUser } from '../../contexts/UserContext';
import { useContext, useState } from 'react';
import FeedbackContext, { TFeedback } from '../../contexts/FeedbackContext';
import SignInWithPhoneNumber from './SignInWithPhoneNumber';
import AppleSignInButton from '../buttons/AppleSignInButton';
import SignInWithVerificationCode from './SignInWithVerificationCode';
import { Color } from '../../Color';
import SignUp from './SignUp';
import GoogleSignInButton from '../buttons/GoogleSignInButton';
import AuthTextField from './TextField';

export enum SignInForm {
    EmailAndPassword,
    PhoneNumber,
    VerificationCode,
    SignUp,
    PasswordReset,
}

const FadingBox = ({ fadeIn, children }: { fadeIn?: boolean; children: React.ReactNode }) => (
    <Fade in={fadeIn} timeout={300}>
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    width: { xs: '100%', sm: 'auto' },
                    height: { xs: '100%', sm: 'auto' },
                    p: 6,
                    border: { xs: 'none', sm: '1px solid #A5A5D1' },
                    borderRadius: { xs: 'none', sm: 3 },
                    backgroundColor: Color.PrimaryDarkGrayBlue,
                    overflowY: 'auto',
                    margin: 'auto',
                }}
            >
                {children}
            </Box>
        </Box>
    </Fade>
);

interface Props {
    onComplete: (status: SignInResult) => void;
    onClose?: () => void;
}

export default function SignIn({ onComplete, onClose }: Props) {
    const { setShowLoading, notify } = useContext(FeedbackContext) as TFeedback;
    const {
        signInWithEmailAndPassword,
        sendPasswordResetEmail,
        // signInWithVerificationCode,
        // signInWithApple,
    } = useContext(UserContext) as TUser;
    const [signInForm, setSignInForm] = useState<SignInForm>();
    const [errorMessage, setErrorMessage] = useState<string>();
    // const [useEmail, setUseEmail] = useState<boolean>(true);
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    const submitEmailAndPassword = async () => {
        try {
            setShowLoading(true);

            const result = await signInWithEmailAndPassword(email, password);
            // console.log('result', result);
            switch (result.status) {
                case SignInStatus.Success:
                    onComplete(result);
                    break;
                case SignInStatus.Error:
                    setErrorMessage(result.error?.message);
                    break;
                default:
                    break;
            }
        } catch (error) {
            setErrorMessage(error as string);
        } finally {
            setShowLoading(false);
        }
    };

    const submitPasswordReset = async () => {
        try {
            setShowLoading(true);
            await sendPasswordResetEmail(email);
            setSignInForm(SignInForm.EmailAndPassword);
        } catch (error) {
            setErrorMessage(error as string);
        } finally {
            await notify('We have emailed you instructions on how to reset your password');
            setShowLoading(false);
        }
    };

    const toggleSignInForm = () => {
        setSignInForm(prev => {
            switch (prev) {
                case SignInForm.EmailAndPassword:
                    return SignInForm.PhoneNumber;
                case SignInForm.PhoneNumber:
                case SignInForm.VerificationCode:
                    return SignInForm.EmailAndPassword;
            }
        });
    };

    useEffect(() => {
        console.log('SignIn: useEffect');
    }, []);

    return (
        <>
            {/* Sign In: Picker */}
            <FadingBox fadeIn={signInForm === undefined}>
                <Stack
                    direction="column"
                    spacing={2}
                    sx={{
                        minWidth: { xs: '100%', sm: '300px' },
                        justifyContent: 'center',
                    }}
                >
                    <img
                        id="SignInLogo"
                        alt="Logo"
                        style={{
                            height: 32,
                            objectFit: 'contain',
                        }}
                    />
                    <Typography
                        variant="mainFont6"
                        sx={{
                            textAlign: 'center',
                            color: Color.White,
                        }}
                    >
                        Sign In
                    </Typography>
                    <Box
                        sx={{
                            height: 16,
                        }}
                    />
                    <GoogleSignInButton setErrorMessage={setErrorMessage} onComplete={onComplete} />
                    <AppleSignInButton setErrorMessage={setErrorMessage} onComplete={onComplete} />
                    <Button
                        variant="irdbGradient"
                        sx={{
                            width: '100%',
                            height: '56px',
                            textTransform: 'none',
                        }}
                        onClick={() => {
                            setSignInForm(SignInForm.EmailAndPassword);
                        }}
                    >
                        Email / Password <i className="fa-solid fa-arrow-right"></i>
                    </Button>
                    {onClose && (
                        <Button variant="irdbText" onClick={onClose}>
                            Close
                        </Button>
                    )}
                </Stack>
            </FadingBox>

            {/* Sign In: Phone */}
            {signInForm === SignInForm.PhoneNumber && (
                <>
                    <SignInWithPhoneNumber
                        toggleSignInForm={toggleSignInForm}
                        setErrorMessage={setErrorMessage}
                        setSignInForm={setSignInForm}
                        onComplete={onComplete}
                    />
                    <Link
                        // onClick={handleSignUp}
                        tabIndex={0}
                        component="button"
                        variant="body2"
                    >
                        {"Don't have an account? Sign Up"}
                    </Link>
                </>
            )}

            {signInForm === SignInForm.VerificationCode && (
                <SignInWithVerificationCode
                    toggleSignInForm={toggleSignInForm}
                    setErrorMessage={setErrorMessage}
                    setSignInForm={setSignInForm}
                    onComplete={onComplete}
                />
            )}

            {/* Sign In: Email */}
            <FadingBox fadeIn={signInForm === SignInForm.EmailAndPassword}>
                <Stack
                    direction="column"
                    spacing={3}
                    sx={{
                        minWidth: { xs: '100%', sm: '300px' },
                        justifyContent: 'center',
                    }}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                        if (e.key === 'Enter') {
                            e.preventDefault();
                            submitEmailAndPassword();
                        }
                    }}
                >
                    <Typography variant="mainFont6" sx={{ textAlign: 'center', color: Color.White }}>
                        Sign In
                    </Typography>
                    {errorMessage && (
                        <Alert
                            severity="error"
                            sx={{ width: '100%' }}
                            /*onClose={handleClose}*/
                        >
                            {errorMessage}
                        </Alert>
                    )}
                    <AuthTextField
                        label="Email"
                        // name="email"
                        // autoComplete="none"
                        value={email}
                        onChange={value => {
                            setEmail(value);
                        }}
                    />
                    <AuthTextField
                        type="password"
                        label="Password"
                        // name="email"
                        // autoComplete="none"
                        value={password}
                        onChange={value => {
                            setPassword(value);
                        }}
                    />
                    <Button
                        variant="irdbText"
                        sx={{
                            fontSize: '12px',
                            justifyContent: 'flex-end',
                            p: 0,
                        }}
                        onClick={() => setSignInForm(SignInForm.PasswordReset)}
                    >
                        Forgot Password?
                    </Button>
                    <Button
                        variant="irdbGradient"
                        sx={{
                            height: '56px',
                            textTransform: 'none',
                        }}
                        onClick={() => submitEmailAndPassword()}
                    >
                        Sign In
                    </Button>
                    <Button
                        variant="irdbText"
                        sx={{
                            // Overrides stack margin
                            '&&': {
                                mt: 4,
                            },
                        }}
                        onClick={() => setSignInForm(SignInForm.SignUp)}
                    >
                        Don't have an account?
                    </Button>
                    <Button variant="irdbText" onClick={() => setSignInForm(undefined)}>
                        Back
                    </Button>
                </Stack>
            </FadingBox>

            {/* Password Reset Email */}
            <FadingBox fadeIn={signInForm === SignInForm.PasswordReset}>
                <Stack
                    direction="column"
                    spacing={3}
                    sx={{
                        minWidth: { xs: '100%', sm: '300px' },
                        justifyContent: 'space-between',
                        height: '100%',
                    }}
                >
                    <Typography
                        variant="mainFont6"
                        sx={{
                            textAlign: 'center',
                            color: Color.White,
                            width: '100%',
                            mt: 3,
                            whiteSpace: 'nowrap',
                        }}
                    >
                        Forgot Password
                    </Typography>
                    {errorMessage && (
                        <Alert severity="error" sx={{ width: '100%' }}>
                            {errorMessage}
                        </Alert>
                    )}
                    <AuthTextField
                        label="Email"
                        value={email}
                        onChange={value => {
                            setEmail(value);
                        }}
                        sx={{ width: '100%' }}
                    />
                    <Box sx={{ flexGrow: 1 }} />
                    <Button
                        variant="irdbGradient"
                        sx={{
                            height: '56px',
                            textTransform: 'none',
                            width: '100%',
                            mb: 2,
                        }}
                        onClick={() => submitPasswordReset()}
                    >
                        Reset Password
                    </Button>
                    <Button
                        variant="irdbText"
                        sx={{ width: '100%' }}
                        onClick={() => setSignInForm(SignInForm.EmailAndPassword)}
                    >
                        Back
                    </Button>
                </Stack>
            </FadingBox>

            {/* Sign Up: Email */}
            <FadingBox fadeIn={signInForm === SignInForm.SignUp}>
                <SignUp
                    onComplete={(status: any) => {
                        onComplete(status);
                    }}
                    onCancel={() => {
                        setSignInForm(SignInForm.EmailAndPassword);
                    }}
                />
            </FadingBox>
        </>
    );
}
