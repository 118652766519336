import { Grid, Stack, Typography } from '@mui/material';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { useContext } from 'react';
import { MetaDataLabel, MetaDataValue } from '../../../../theme/units';
import TextField from '../../../general/TextField';
import ThemeContext, { TTheme } from '../../../../contexts/ThemeContext';
import { Color } from '../../../../Color';
import { TFirstName } from '../../../../types/MetaTypes';

const FirstName = ({ metaField }: MetaFieldProps) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TFirstName | undefined;

    if (!content) {
        return null;
    }

    return (
        <>
            <Grid item container direction="row">
                <Grid item {...MetaDataLabel}>
                    <Typography
                        variant="metaDataLabel"
                        sx={{
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                        }}
                    >
                        First Name
                    </Typography>
                </Grid>
                <Grid item {...MetaDataValue}>
                    <Typography variant="metaDataValue" sx={{}}>
                        {content.firstName}
                    </Typography>
                </Grid>
            </Grid>
        </>
    );
};

const FirstNameForm = ({ metaField, onCopy, onChange }: MetaFieldFormProps) => {
    const content = metaField?.metaContent as TFirstName;

    const handleChange = (field: { name: string; value: string }) => {
        onChange?.({
            ...content,
            [field.name]: field.value,
        });
    };

    return (
        <Stack direction="column" spacing={2}>
            <Stack
                direction="row"
                spacing={2}
                sx={{
                    alignItems: 'center',
                }}
            >
                <TextField
                    label="First Name"
                    value={content?.firstName ?? ''}
                    onChange={value => handleChange({ name: 'firstName', value })}
                    onCopy={
                        onCopy ?
                            () => {
                                onCopy(content);
                            }
                        :   undefined
                    }
                    wrapperSx={{
                        flexGrow: 1,
                    }}
                />
            </Stack>
        </Stack>
    );
};

export { FirstName, FirstNameForm };
