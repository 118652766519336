import { Box, CircularProgress, Dialog, Stack } from '@mui/material';
import NavBar from '../tools/NavBar';
import { Outlet } from 'react-router-dom';
import Footer from '../tools/Footer';
import { useContext, useEffect, useState } from 'react';
import UserContext, { SignInResult, SignInStatus, TUser, UserType } from '../../../contexts/UserContext';
import SignIn from '../../auth/SignIn';
import { Color } from '../../../Color';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import FeedbackContext, { TFeedback } from '../../../contexts/FeedbackContext';
import ApplicationError from '../../ApplicationError';

export interface SignInDialogProps {
    open: boolean;
    transitionDuration: number;
    onClose: (open: boolean) => void;
}

function SignInDialog({ open, transitionDuration, onClose }: SignInDialogProps) {
    const handleClose = () => {
        onClose(false);
    };

    return (
        <Dialog
            fullScreen
            transitionDuration={transitionDuration}
            onClose={handleClose}
            open={open}
            sx={{
                '& .MuiDialog-paper': {
                    backgroundColor: Color.Black,
                    backgroundImage: {
                        xs: 'none',
                        sm: 'url(/images/signInBackground.svg)',
                    },
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'top right',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }}
        >
            <SignIn
                onComplete={(result: SignInResult) => {
                    console.log('result', result);
                    // onClose(result);
                    switch (result.status) {
                        case SignInStatus.Success:
                            handleClose();
                            break;
                        case SignInStatus.SignUp:
                            break;
                        default:
                            handleClose();
                            break;
                    }
                }}
            />
        </Dialog>
    );
}

export default function Dashboard() {
    // const { showDashboard } = useContext(EnvContext) as TEnv;
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { confirm } = useContext(FeedbackContext) as TFeedback;
    const { user, userIsAnonymous, userError, getUser } = useContext(UserContext) as TUser;

    const [isSignInDialogOpen, setIsSignInDialogOpen] = useState(false);

    useEffect(() => {
        if (!user && !!userIsAnonymous) {
            setIsSignInDialogOpen(true);
        } else if (!!user && userIsAnonymous === false) {
            setIsSignInDialogOpen(false);
        }
    }, [user, userIsAnonymous]);

    // if (showDashboard === undefined) {
    //     // This avoids the brief flicker of Page Not Found while we wait for showDashboard to be set by Remote Config
    //     return null;
    // }

    // console.log('user', user);
    return (
        <>
            {user ?
                <Stack
                    direction="column"
                    sx={{
                        backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightGray,
                    }}
                >
                    <Stack direction={{ xs: 'column', sm: 'row' }} spacing={0}>
                        <NavBar />
                        <Box
                            sx={{
                                width: { xs: '100%', sm: 'auto' },
                                minWidth: 0,
                                flex: '1 1 auto',
                            }}
                        >
                            <Outlet />
                        </Box>
                    </Stack>
                    <Footer />
                </Stack>
            : userError ?
                <ApplicationError error={userError} />
            :   null}
            <SignInDialog
                open={isSignInDialogOpen}
                transitionDuration={0}
                onClose={async () => {
                    const user = await getUser();
                    if ((user?.internalAdmin ?? 0) > 0) {
                        const navigateToAdmin = await confirm({
                            title: 'IRCODE',
                            message: 'Would you like to go to the admin site?',
                        });
                        if (navigateToAdmin) {
                            window.location.href = '/iaw';
                        }
                    } else if (user?.userType === UserType.Enterprise) {
                        window.location.href = '/enterprise';
                    }
                }}
            />
            <Dialog
                fullScreen
                transitionDuration={user ? 1000 / 3 : 0}
                open={userIsAnonymous === undefined && user === undefined && userError === undefined}
                sx={{
                    '& .MuiDialog-paper': {
                        backgroundColor: Color.PrimaryDarkGrayBlue,
                        alignItems: 'center',
                        justifyContent: 'center',
                    },
                }}
            >
                <CircularProgress
                    sx={{
                        color: Color.White,
                    }}
                />
            </Dialog>
        </>
    );
}
