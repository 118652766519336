import React, { useContext, useState } from 'react';
import { TextField, Button, Box, Typography, Dialog, Stack } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import AdminContext, { TAdmin, UserSearchResults } from '../../contexts/AdminContext';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { IrcodeUserSearch } from './IrcodeUserSearch';
import { formatDate } from 'src/util/date';
import FeedbackContext, { TFeedback } from 'src/contexts/FeedbackContext';
import { Color } from 'src/Color';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';

export default function SdkKeys() {
    const [apiKey, setApiKey] = useState('');
    const [expires, setExpires] = useState<DateTime | null>(DateTime.now());
    const [bucketName, setBucketName] = useState('');
    const [associatedUserID, setAssociatedUserID] = useState<number>(1);
    const [projectID, setProjectID] = useState<number>(1);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userSearchDialogOpen, setUserSearchDialogOpen] = useState(false);
    const [validateDialogOpen, setValidateDialogOpen] = useState(false);
    const [validationKey, setValidationKey] = useState('');
    const [validationResult, setValidationResult] = useState<{ validKey: boolean; expires: number } | null>(null);
    const [validationResultDialogOpen, setValidationResultDialogOpen] = useState(false);
    const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
    const [removeKey, setRemoveKey] = useState('');
    const [updateDialogOpen, setUpdateDialogOpen] = useState(false);
    const { darkMode } = useContext(ThemeContext) as TTheme;

    const { fetchNewSDKKey, saveSDKKey, updateSDKKey, removeSDKKey, validateSDKKey } = useContext(
        AdminContext,
    ) as TAdmin;
    const { confirm, notify, setShowLoading } = useContext(FeedbackContext) as TFeedback;

    const handleFetchNewKey = async () => {
        setLoading(true);
        try {
            const newKey = await fetchNewSDKKey();
            if (newKey) {
                setApiKey(newKey.apikey);
                notify('Be sure to copy your key down as this will only show once', `${newKey.apikey}`);

                setDialogOpen(true);
            }
        } catch (error) {
            console.error('Failed to fetch SDK Key:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSaveKey = async () => {
        if (!apiKey || !expires || !bucketName) {
            alert('Please fill all fields');
            return;
        }

        const confirmed = await confirm({
            title: 'Confirm SDK Key Details',
            message: `
                Expiration: ${expires?.toFormat('MM/dd/yyyy')}
                Bucket Name: ${bucketName}
                Associated User ID: ${associatedUserID}
             `,
            yes: 'Save',
            no: 'Cancel',
        });

        if (confirmed) {
            const expirationInSeconds = Math.floor(expires.toJSDate().getTime() / 1000);
            const result = await saveSDKKey(apiKey, expirationInSeconds, bucketName, associatedUserID, projectID);
            if (result) {
                notify('SDK Key saved', 'New SDK Key has been saved successfully');
                setDialogOpen(false);
            } else {
                console.error('Failed to save SDK Key');
            }
        }
    };

    const handleValidateKey = async () => {
        if (!validationKey) {
            setValidationResult({
                validKey: false,
                expires: 0,
            });
            setValidationResultDialogOpen(true);
            setValidateDialogOpen(false);
            return;
        }

        const result = await validateSDKKey(validationKey);
        if (result) {
            setValidationResult({
                validKey: result.validKey,
                expires: result.expires,
            });
        } else {
            setValidationResult({
                validKey: false,
                expires: 0,
            });
        }
        setValidationResultDialogOpen(true);
        setValidateDialogOpen(false);
    };

    const handleUpdateKey = async () => {
        if (!apiKey || !expires || !bucketName) {
            alert('Please fill all fields');
            return;
        }

        const confirmed = await confirm({
            title: 'Confirm Update SDK Key',
            message: `
            Expiration: ${expires?.toFormat('MM/dd/yyyy')}
            Bucket Name: ${bucketName}
            Associated User ID: ${associatedUserID}
           `,
            yes: 'Update',
            no: 'Cancel',
        });

        if (confirmed) {
            const expirationInSeconds = Math.floor(expires.toJSDate().getTime() / 1000);
            const result = await updateSDKKey(apiKey, expirationInSeconds, bucketName, associatedUserID, projectID);
            if (result) {
                notify('SDK Key updated', 'SDK Key has been updated successfully');
                setUpdateDialogOpen(false);
            } else {
                console.error('Failed to update SDK Key');
            }
        }
    };

    const handleUserSelect = (user: UserSearchResults) => {
        setAssociatedUserID(user.userID);
        setUserSearchDialogOpen(false);
    };

    const handleRemoveKey = async () => {
        if (!removeKey) {
            alert('Please enter the SDK Key to remove');
            return;
        }

        const confirmed = await confirm({
            title: 'Remove SDK Key',
            message: 'Are you sure you want to remove this SDK key?',
            yes: 'Remove',
            no: 'Cancel',
            destructive: true,
        });

        if (confirmed) {
            setShowLoading(true);
            try {
                const result = await removeSDKKey(removeKey);
                if (result) {
                    notify('SDK Key removed', 'SDK removed successfully');
                } else {
                    console.error('Failed to remove SDK Key');
                }
            } catch (error) {
                console.error('Error while removing SDK Key:', error);
            } finally {
                setShowLoading(false);
            }
            setRemoveDialogOpen(false);
        }
    };

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                m: 'auto',
                p: 4,
                borderRadius: '8px',
                width: { sm: '100%', md: '90%' },
                backgroundColor: theme => (theme.palette.mode === 'dark' ? Color.DarkModeBlack : Color.White),
                boxShadow: 1,
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    mt: 2,
                }}
            >
                <Typography variant="mainFont6">SDK Key Management</Typography>
            </Box>
            <Stack direction="column" sx={{ alignItems: 'center', gap: 2, mt: 4 }}>
                <Button variant="editProfileImageUpload" sx={{ minWidth: '200px' }} onClick={handleFetchNewKey}>
                    Fetch New SDK Key
                </Button>
                <Button
                    variant="editProfileImageUpload"
                    sx={{ minWidth: '200px' }}
                    onClick={() => setUpdateDialogOpen(true)}
                >
                    Update SDK Key
                </Button>
                <Button
                    variant="editProfileImageUpload"
                    sx={{ minWidth: '200px' }}
                    onClick={() => setValidateDialogOpen(true)}
                >
                    Validate SDK Key
                </Button>
                <Button
                    variant="editProfileImageUpload"
                    sx={{ minWidth: '200px' }}
                    onClick={() => setRemoveDialogOpen(true)}
                >
                    Remove SDK Key
                </Button>
            </Stack>

            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} fullWidth>
                <Box sx={{ p: 2 }}>
                    <Typography variant="webLabel2" sx={{ pb: 2 }}>
                        Enter SDK key details and save
                    </Typography>

                    <Stack direction="column" sx={{ gap: 2 }}>
                        <Typography variant="lineDataLabel" sx={{ wordBreak: 'break-word' }}>
                            API Key: {apiKey}
                        </Typography>
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker
                                label="Expiration Date"
                                value={expires}
                                onChange={(newValue: DateTime | null) => setExpires(newValue)}
                            />
                        </LocalizationProvider>

                        <TextField
                            label="Bucket Name"
                            value={bucketName}
                            onChange={e => setBucketName(e.target.value)}
                        />
                        <TextField
                            label="Associated User ID"
                            value={associatedUserID}
                            onChange={e => setAssociatedUserID(Number(e.target.value))}
                        />
                        <Button onClick={() => setUserSearchDialogOpen(true)} variant="contained">
                            Search for User
                        </Button>
                        {/* <TextField
                            label="Project ID"
                            value={projectID}
                            onChange={e => setProjectID(Number(e.target.value))}
                        /> */}
                    </Stack>
                    <Stack direction="row" spacing={2} sx={{ pt: 2, alignItems: 'center', justifyContent: 'center' }}>
                        <Button onClick={handleSaveKey} variant="contained">
                            Save
                        </Button>
                        <Button onClick={() => setDialogOpen(false)} variant="irdbText">
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Dialog>

            <Dialog open={validateDialogOpen} onClose={() => setValidateDialogOpen(false)}>
                <Stack direction="column" sx={{ gap: 2, p: 2 }}>
                    <Typography variant="webLabel2" sx={{ pb: 2 }}>
                        Validate SDK Key
                    </Typography>
                    <TextField
                        label="SDK Key"
                        value={validationKey}
                        onChange={e => setValidationKey(e.target.value)}
                        fullWidth
                    />
                    <Button onClick={handleValidateKey} variant="contained">
                        Validate
                    </Button>
                    <Button onClick={() => setValidateDialogOpen(false)} variant="irdbText">
                        Cancel
                    </Button>
                </Stack>
            </Dialog>

            <Dialog open={userSearchDialogOpen} onClose={() => setUserSearchDialogOpen(false)}>
                <IrcodeUserSearch closeDialog={() => setUserSearchDialogOpen(false)} onUserSelect={handleUserSelect} />
            </Dialog>

            <Dialog open={validationResultDialogOpen} onClose={() => setValidationResultDialogOpen(false)}>
                <Stack direction="column" sx={{ gap: 2, p: 2 }}>
                    <Typography variant="h6">Validation Result</Typography>
                    {validationResult ?
                        <Box>
                            <Typography>Valid Key: {validationResult.validKey ? 'Yes' : 'No'}</Typography>
                            <Typography>Expiration Date: {formatDate(validationResult.expires)}</Typography>
                        </Box>
                    :   <Typography color="error">SDK Key is invalid or could not be validated.</Typography>}
                    <Button onClick={() => setValidationResultDialogOpen(false)} color="primary" variant="contained">
                        Close
                    </Button>
                </Stack>
            </Dialog>

            <Dialog open={updateDialogOpen} onClose={() => setUpdateDialogOpen(false)} fullWidth>
                <Box sx={{ p: 2 }}>
                    <Stack sx={{ gap: 2, p: 2 }}>
                        <Typography variant="h6" sx={{ pb: 2 }}>
                            Update SDK Key
                        </Typography>
                        <TextField
                            label="API Key"
                            value={apiKey}
                            onChange={e => setApiKey(e.target.value)}
                            sx={{ overflow: 'wrap' }}
                        />
                        <LocalizationProvider dateAdapter={AdapterLuxon}>
                            <DatePicker
                                label="Expiration Date"
                                value={expires}
                                onChange={(newValue: DateTime | null) => setExpires(newValue)}
                            />
                        </LocalizationProvider>
                        <TextField
                            label="Bucket Name"
                            value={bucketName}
                            onChange={e => setBucketName(e.target.value)}
                        />
                        <Button onClick={() => setUserSearchDialogOpen(true)} variant="contained">
                            Search for User
                        </Button>
                        <TextField
                            label="Associated User ID"
                            value={associatedUserID}
                            onChange={e => setAssociatedUserID(Number(e.target.value))}
                        />

                        <TextField
                            label="Project ID"
                            value={projectID}
                            onChange={e => setProjectID(Number(e.target.value))}
                        />
                        <Button onClick={handleUpdateKey} color="primary" variant="contained">
                            Update
                        </Button>
                        <Button onClick={() => setUpdateDialogOpen(false)} variant="irdbText">
                            Cancel
                        </Button>
                    </Stack>
                </Box>
            </Dialog>

            <Dialog open={removeDialogOpen} onClose={() => setRemoveDialogOpen(false)}>
                <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, p: 2 }}>
                    <Typography variant="h6">Remove SDK Key</Typography>
                    <TextField
                        label="SDK Key"
                        value={removeKey}
                        onChange={e => setRemoveKey(e.target.value)}
                        fullWidth
                    />
                    <Button onClick={handleRemoveKey} color="error" variant="contained">
                        Remove
                    </Button>
                    <Button onClick={() => setRemoveDialogOpen(false)} color="secondary">
                        Cancel
                    </Button>
                </Box>
            </Dialog>
        </Stack>
    );
}
