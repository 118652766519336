import { FC, createContext, useState } from 'react';
import { useMediaQuery, useTheme } from '@mui/material';
import { User } from './UserContext';
import { Helmet } from 'react-helmet';

enum LocalStorageKey {
    CaptureMode = 'CaptureMode',
}

export interface TEnv {
    sdkapikey?: string;
    client?: string;
    impersonated?: User;
    impersonate: (user: User | undefined) => void;
    ocrEnabled: boolean;
    // showWebApp?: boolean;
    // setShowWebApp: (show: boolean) => void;
    // showAdmin?: boolean;
    // setShowAdmin: (show: boolean) => void;
    // showDashboard?: boolean;
    // setShowDashboard: (show: boolean) => void;
}

interface Props {
    children: React.ReactNode;
}

export const EnvProvider: FC<Props> = ({ children }) => {
    const client = process.env.REACT_APP_CLIENT;
    const [impersonated, impersonate] = useState<User>();

    const [showWebApp, setShowWebApp] = useState(true);
    const [showAdmin, setShowAdmin] = useState(true);
    const [showDashboard, setShowDashboard] = useState(true);

    // Testing, remove when done
    const theme = useTheme();
    const matchesXs = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
    const matchesSm = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    const matchesMd = useMediaQuery(theme.breakpoints.between('md', 'lg'));
    const matchesLg = useMediaQuery(theme.breakpoints.between('lg', 'xl'));
    const matchesXl = useMediaQuery(theme.breakpoints.up('xl'));

    let currentBreakpoint = 'unknown';
    if (matchesXs) currentBreakpoint = 'xs';
    else if (matchesSm) currentBreakpoint = 'sm';
    else if (matchesMd) currentBreakpoint = 'md';
    else if (matchesLg) currentBreakpoint = 'lg';
    else if (matchesXl) currentBreakpoint = 'xl';
    console.log('currentBreakpoint', currentBreakpoint);
    // Testing, remove when done

    return (
        <EnvContext.Provider
            value={{
                sdkapikey: process.env.REACT_APP_SDKAPIKEY,
                client,
                impersonated,
                impersonate,
                ocrEnabled: Boolean(process.env.REACT_APP_OCR_ENABLED),
                // showWebApp,
                // setShowWebApp,
                // showAdmin,
                // setShowAdmin,
                // showDashboard,
                // setShowDashboard,
            }}
        >
            {client && (
                <Helmet>
                    <link rel="stylesheet" type="text/css" href={`/clients/${client}/styles.css`} />
                </Helmet>
            )}
            {children}
        </EnvContext.Provider>
    );
};

const EnvContext = createContext<TEnv | undefined>(undefined);

export default EnvContext;
