import { useContext } from 'react';
import { Stack, Typography } from '@mui/material';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { nullUndefinedOrEmpty } from '../../util/string';
import TextField from '../general/TextField';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import { TFirstName } from '../../types/MetaTypes';

interface Props extends MetaFieldProps {
    showEditing?: boolean;
    onEdit?: () => void;
}

const FirstName = ({ metaField, showEditing, onEdit }: Props) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TFirstName;

    if (!content || nullUndefinedOrEmpty(content?.firstName)) {
        return null;
    }

    return (
        <Stack
            direction="column"
            spacing={0}
            sx={{
                // TODO: Here or on the parent?
                px: 3,
            }}
        >
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '32px',
                    fontWeight: 600,
                    lineHeight: '32px',
                    letterSpacing: '-0.03em',
                    textAlign: 'left',
                    wordBreak: 'break-word',
                }}
            >
                {nullUndefinedOrEmpty(content.firstName) ? '' : content.firstName}
            </Typography>
        </Stack>
    );
};

const FirstNameForm = ({ metaField, disabled, onChange }: MetaFieldFormProps) => {
    const content = metaField?.metaContent as TFirstName | undefined;

    const handleChange = (field: { name: string; value: string }) => {
        onChange?.({
            ...content,
            [field.name]: field.value,
        });
    };

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                // TODO: Here or on the parent?
                px: 2,
            }}
        >
            <TextField
                label="First Name"
                placeholder="First Name"
                value={content?.firstName ?? ''}
                disabled={disabled}
                onChange={value => handleChange({ name: 'firstName', value })}
            />
        </Stack>
    );
};

export { FirstName, FirstNameForm };
