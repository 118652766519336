import { useContext } from 'react';
import { Stack, Typography } from '@mui/material';
import { MetaFieldFormProps, MetaFieldProps } from './Props';
import { nullUndefinedOrEmpty } from '../../util/string';
import TextField from '../general/TextField';
import ThemeContext, { TTheme } from '../../contexts/ThemeContext';
import { TTitle } from '../../types/MetaTypes';

interface Props extends MetaFieldProps {
    showEditing?: boolean;
    onEdit?: () => void;
}

const Title = ({ metaField, showEditing, onEdit }: Props) => {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const content = metaField?.metaContent as TTitle;

    if (!content || nullUndefinedOrEmpty(content?.title)) {
        return null;
    }

    return (
        <Stack
            direction="column"
            spacing={0}
            sx={{
                // TODO: Here or on the parent?
                px: 3,
            }}
        >
            <Typography
                sx={{
                    fontFamily: 'Nocturne Serif',
                    fontSize: '32px',
                    fontWeight: 600,
                    lineHeight: '32px',
                    letterSpacing: '-0.03em',
                    textAlign: 'left',
                    wordBreak: 'break-word',
                }}
            >
                {nullUndefinedOrEmpty(content.title) ? 'Untitled' : content.title}
            </Typography>
        </Stack>
    );
    // return (
    //     <Typography
    //         sx={{
    //             paddingX: 3,
    //             // Wrapped text was busting into the padding
    //             // boxDecorationBreak: 'clone',

    //             fontFamily: 'Nocturne Serif',
    //             fontSize: '32px',
    //             fontWeight: 600,
    //             lineHeight: '40px',
    //             letterSpacing: '-0.03em',
    //             textAlign: 'left',
    //             wordBreak: 'break-word',
    //         }}
    //     >
    //         {content.title}
    //     </Typography>
    // );
};

const TitleForm = ({ metaField, hidden, onChange }: MetaFieldFormProps) => {
    const content = metaField?.metaContent as TTitle | undefined;

    const handleChange = (field: { name: string; value: string }) => {
        onChange?.({
            ...content,
            [field.name]: field.value,
        });
    };

    if (hidden) {
        return <input type="hidden" name="cardType" value={content?.title} />;
    }

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                // TODO: Here or on the parent?
                px: 2,
            }}
        >
            <TextField
                label="Title"
                placeholder="Title"
                value={content?.title ?? ''}
                onChange={value => handleChange({ name: 'title', value })}
            />
        </Stack>
    );
};

export { Title, TitleForm };
