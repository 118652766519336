import { FC, createContext, useContext, useEffect, useRef, useState } from 'react';
import { FirebaseError } from 'firebase/app';
import { ActionCodeSettings, ConfirmationResult, OAuthProvider, RecaptchaVerifier } from 'firebase/auth';
import {
    createUserWithEmailAndPassword,
    deleteUser as firebaseDeleteUser,
    getAuth,
    onAuthStateChanged,
    signInAnonymously,
    signInWithEmailAndPassword as firebaseSignInWithEmailAndPassword,
    signInWithPhoneNumber as firebaseSignInWithPhoneNumber,
    signInWithPopup,
    signOut as firebaseSignOut,
    // User as FirebaseUser,
    sendPasswordResetEmail as firebaseSendPasswordResetEmail,
    updatePassword as firebaseUpdatePassword,
    reauthenticateWithCredential,
    EmailAuthProvider,
} from 'firebase/auth';
import { Operation } from './Operation';
import FeedbackContext, { TFeedback } from './FeedbackContext';
import TwoFactorAuthDialog, { TwoFactorAuthDialogProps } from '../components/admin/tools/TwoFactorAuthDialog';
import { useCapturedStateWorkaround } from '../hooks/useCapturedStateWorkaround';
import useApi, { Method } from 'src/hooks/useApi';
import { getFBAuthErrorMessage } from 'src/util/firebase';
import { PagedResults } from './MediaContext';

export interface FilePreview extends File {
    preview: string;
}

interface UserResult {
    User: User;
}

// TODO: ImageUploadResult or something
export interface Result {
    Completed: boolean;
    Results: {
        Image: {
            vectorID: string;
        };
    };
}

export interface AuthProviders {
    password: boolean;
    google: boolean;
    apple: boolean;
}

export interface IRCodeCounts {
    totalArchivedCount?: number;
    totalHistoryCount?: number;
    totalImagesCount?: number;
    totalNotificationsCount?: number;
    totalSavedCount?: number;
    countRead?: number;
    countUnRead?: number;
}

export interface User extends IRCodeCounts {
    // appleID?: string;
    email?: string;
    fullName?: string;
    phone?: string;
    profileUrl?: string;
    userID?: number;
    userName?: string;
    userType: UserType;
    userTypeDisplay: string;
    authProviders: AuthProviders;
    internalAdmin: number;
}

export enum SignInStatus {
    NeedsOTP = 'NeedsOTP',
    Success = 'Success',
    // TODO: Keeping this one around?
    SignUp = 'SignUp',
    Error = 'Error',
}

export interface SignInResult {
    status: SignInStatus;
    error?: Error;
}

export enum SignUpStatus {
    Success = 'Success',
    Error = 'Error',
}

export interface SignUpResult {
    status: SignUpStatus;
    error?: Error;
}

export interface UserNotification {
    notificationID: number;
    notificationType: string;
    information: string;
    readFlag: boolean;
    markAll: boolean;
    created: number;
    displayLine: string;
    ActivityUser: {
        email: string;
        phone: string;
        userID: number;
        fullName: string;
        userName: string;
        profileUrl: string;
    };
    imageUrl: string;
    imageID: string;
    title: string;
}

export type PagedNotifications = PagedResults<{
    Notifications: UserNotification[];
    countRead: number;
    countUnRead: number;
}>;

// export interface UserNotificationResults { // extends PagedResults<UserNotification> {
//     Notifications: UserNotification[];
//     countRead: number;
//     countUnRead: number;
// }

type TwoFactorAuthParams = (title: string, phoneNumber: string, confirm: string) => Promise<string>;

export interface TUser {
    userIsAnonymous?: boolean;
    user?: User;
    userError?: string;
    getUser: () => Promise<User | undefined>;
    signInWithEmailAndPassword: (email: string, password: string) => Promise<SignInResult>;
    signInWithPhoneNumber: (phoneNumber: string) => Promise<void>;
    signInWithVerificationCode: (verificationCode: string) => Promise<SignInResult>;
    signInWithApple: () => Promise<SignInResult>;
    signInWithGoogle: () => Promise<SignInResult>;
    signUpWithEmailAndPassword: ({
        email,
        password,
        fullName,
        userName,
    }: {
        email: string;
        password: string;
        fullName: string;
        userName: string;
    }) => Promise<SignUpResult>;
    reauthenticateWithPassword: (password: string) => Promise<void>;
    signOut: () => void;
    deleteUser: () => Promise<void>;
    sendPasswordResetEmail(email: string, actionCodeSettings?: ActionCodeSettings | null): Promise<void>;
    editProfile: (
        email: string,
        fullName: string,
        userName: string,
        phone: string,
        profileUrl?: string,
    ) => Promise<void>;
    addProfileAvatar: (profileUrl: string) => Promise<void>;
    removeProfileAvatar: () => Promise<void>;
    // fetchUserNotifications: (offset: number) => Promise<UserNotification[]>;
    fetchUserNotifications: (offset: number) => Promise<PagedNotifications>;
    notificationAllRead: (markAll: boolean) => Promise<UserNotification[]>;
    notificationRead: (readFlag: boolean, notificationID: number) => Promise<UserNotification[]>;
    onSignInSuccess?: () => void;
    updatePassword: (newPassword: string) => Promise<void>;
    requestTwoFactorAuth: TwoFactorAuthParams;
}

export enum UserType {
    Anonymous = 'Anonymous',
    Basic = 'Basic',
    Pro = 'Pro Monthly',
    ProYearly = 'Pro Yearly',
    Enterprise = 'Enterprise',
    AdminPro = 'AdminPro',
    AdminEnterprise = 'AdminEnterprise',
}

const mapProviders = (providers: { providerId: string }[]): AuthProviders => {
    return providers.reduce<AuthProviders>(
        (prev, curr) => {
            switch (curr.providerId) {
                case 'password':
                    return { ...prev, password: true };
                case 'google.com':
                    return { ...prev, google: true };
                case 'apple.com':
                    return { ...prev, apple: true };
                default:
                    return prev;
            }
        },
        { password: false, google: false, apple: false },
    );
};

interface Props {
    children: React.ReactNode;
}

export const UserProvider: FC<Props> = ({ children }) => {
    const { request } = useApi();
    const { setShowLoading } = useContext(FeedbackContext) as TFeedback;
    // const {
    //     showTwoFactorAuthForm,
    //     TwoFactorAuthDialog
    // } = useTwoFactorAuth();

    const auth = getAuth();
    const [userIsAnonymous, setUserIsAnonymous] = useState<boolean>();
    const [user, setUser] = useState<User>();
    const unsubscribe = useRef<Function>();
    const [userError, setUserError] = useState<string>();
    const [notification, setNotification] = useState<UserNotification[]>([]);

    const confirmationResultRef = useRef<ConfirmationResult>();
    const [verifier, setVerifier] = useState<RecaptchaVerifier>();

    const createUser = async ({
        email,
        phone,
        fullName,
        userName,
    }: {
        email?: string;
        phone?: string;
        fullName?: string;
        userName?: string;
    } = {}): Promise<void> => {
        try {
            const FBUserID = auth.currentUser?.uid;
            const response = await request({
                method: Method.POST,
                path: '/User',
                data: {
                    FBUserID,
                    email,
                    phone,
                    fullName,
                    userName,
                },
                useSdkApiKey: false,
            });
        } catch (error) {
            console.error(error);
        }
    };

    const getUser = async (): Promise<User | undefined> => {
        try {
            const FBUserID = auth.currentUser?.uid;
            if (!FBUserID) {
                throw new Error('User is not authenticated');
            }
            const response = await request({
                method: Method.GET,
                path: '/User',
                useSdkApiKey: false,
            });

            const user = (response.data as Operation<UserResult>).Results?.User;
            if (user) {
                // We only want to allow email/password users to be able to change their password
                user.authProviders = mapProviders(auth.currentUser?.providerData ?? []);
            }
            return user;
        } catch (error) {
            return undefined;
        }
    };

    const addProfileAvatar = async (profileUrl: string): Promise<void> => {
        try {
            const FBUserID = auth.currentUser?.uid;
            if (!FBUserID) {
                throw new Error('User is not authenticated');
            }
            const response = await request({
                method: Method.PUT,
                path: '/User',
                data: {
                    profileUrl,
                },
            });
            const refreshUser = await getUser();
            if (refreshUser) {
                setUser(refreshUser);
            }
        } catch (error) {
            console.error('Error updating profile pic', error);
        }
    };

    const removeProfileAvatar = async (): Promise<void> => {
        try {
            const FBUserID = auth.currentUser?.uid;
            if (!FBUserID) {
                throw new Error('User is not authenticated');
            }
            const response = await request({
                method: Method.DELETE,
                path: '/User/profileUrl',
            });
            const refreshUser = await getUser();
            if (refreshUser) {
                setUser(refreshUser);
            }
        } catch (error) {
            console.error('Error Removing profile pic', error);
        }
    };

    const editProfile = async (
        email: string,
        fullName: string,
        userName: string,
        phone: string,
        profileUrl?: string,
    ): Promise<void> => {
        try {
            const FBUserID = auth.currentUser?.uid;
            if (!FBUserID) {
                throw new Error('User is not authenticated');
            }

            const response = await request({
                method: Method.PUT,
                path: '/User',
                data: {
                    email,
                    userName,
                    fullName,
                    phone,
                    ...(profileUrl && { profileUrl }),
                },
            });

            const refreshUser = await getUser();
            if (refreshUser) {
                setUser(refreshUser);
            }
        } catch (error) {
            console.error('Error updating profile:', error);
        }
    };

    const signUpWithEmailAndPassword = async ({
        email,
        password,
        fullName,
        userName,
    }: {
        email: string;
        password: string;
        fullName: string;
        userName: string;
    }): Promise<SignUpResult> => {
        try {
            const userNameAvailable = await isUserNameAvailable(userName);
            if (!userNameAvailable) {
                throw new Error('User name already in use.');
            }
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);

            if (userCredential.user) {
                await createUser({
                    email,
                    fullName,
                    userName,
                });

                const user = await getUser();
                setUser(user);

                return {
                    status: SignUpStatus.Success,
                };
            } else {
                throw new Error('User not created');
            }
        } catch (error: unknown) {
            console.error(error);

            let useError = error;
            if (error instanceof FirebaseError) {
                const errorMessage = getFBAuthErrorMessage(error);
                if (errorMessage) {
                    useError = new Error(errorMessage);
                }
            }
            return {
                status: SignUpStatus.Error,
                error: useError instanceof Error ? useError : new Error('Unknown error occurred.'),
            };
        }
    };

    const signInWithPhoneNumber = async (phoneNumber: string): Promise<void> => {
        try {
            confirmationResultRef.current = await firebaseSignInWithPhoneNumber(auth, phoneNumber, verifier!);
            return;
        } catch (error: unknown) {
            console.error(error);
        }

        throw new Error('Failed to sign in with phone number');
    };

    const signInWithVerificationCode = async (verificationCode: string): Promise<SignInResult> => {
        try {
            // const verifier = new RecaptchaVerifier(auth, 'recaptcha', {
            //     size: 'invisible',
            //     callback: (response: any) => {
            //         console.log('captcha solved');
            //     },
            // })

            const result = confirmationResultRef.current?.confirm(verificationCode);
            confirmationResultRef.current = undefined;

            return {
                status: SignInStatus.Success,
            };
        } catch (error: unknown) {
            console.error(error);
        }

        return {
            status: SignInStatus.Success,
        };
    };

    const sendPasswordResetEmail = async (email: string) => {
        try {
            await firebaseSendPasswordResetEmail(auth, email);
        } catch (error) {
            console.error(error);
        }
    };

    const updatePassword = async (newPassword: string): Promise<void> => {
        try {
            const currentUser = auth.currentUser;
            if (!currentUser || !currentUser.email) {
                throw new Error('User not found');
            }
            await firebaseUpdatePassword(currentUser, newPassword);
        } catch (error) {
            console.error('error', error);
            throw error;
        }
    };

    const signInWithEmailAndPassword = async (email: string, password: string): Promise<SignInResult> => {
        try {
            await firebaseSignInWithEmailAndPassword(auth, email, password);

            let user = await getUser();

            // Temporary fix for authenticated (firebase) and anonymous (irdb) users
            if (user?.userType === UserType.Anonymous) {
                await createUser({});
                user = await getUser();
            }

            if (user) {
                setUser(user);
                return {
                    status: SignInStatus.Success,
                };
            } else {
                throw new Error('User not found, try signing up.');
            }
        } catch (error: unknown) {
            console.error(error);

            let useError = error;
            if (error instanceof FirebaseError) {
                const errorMessage = getFBAuthErrorMessage(error);
                if (errorMessage) {
                    useError = new Error(errorMessage);
                }
            }

            return {
                status: SignInStatus.Error,
                error: useError instanceof Error ? useError : new Error('Unknown error occurred.'),
            };
        }
    };

    const signInWithApple = async (): Promise<SignInResult> => {
        try {
            const provider = new OAuthProvider('apple.com');
            await signInWithPopup(auth, provider);
            const user = await getUser();
            if (user) {
                setUser(user);
                return {
                    status: SignInStatus.Success,
                };
            } else {
                throw new Error('User not found, try signing up.');
            }
        } catch (error: unknown) {
            console.error(error);

            let useError = error;
            if (error instanceof FirebaseError) {
                const errorMessage = getFBAuthErrorMessage(error);
                if (errorMessage) {
                    useError = new Error(errorMessage);
                }
            }

            return {
                status: SignInStatus.Error,
                error: useError instanceof Error ? useError : new Error('Unknown error occurred.'),
            };
        }
    };

    const signInWithGoogle = async (): Promise<SignInResult> => {
        try {
            const provider = new OAuthProvider('google.com');
            await signInWithPopup(auth, provider);
            const user = await getUser();
            if (user) {
                setUser(user);

                return {
                    status: SignInStatus.Success,
                };
            } else {
                throw new Error('User not found, try signing up.');
            }
        } catch (error: unknown) {
            console.error(error);

            let useError = error;
            if (error instanceof FirebaseError) {
                const errorMessage = getFBAuthErrorMessage(error);
                if (errorMessage) {
                    useError = new Error(errorMessage);
                }
            }

            return {
                status: SignInStatus.Error,
                error: useError instanceof Error ? useError : new Error('Unknown error occurred.'),
            };
        }
    };

    const reauthenticateWithPassword = async (password: string): Promise<void> => {
        if (!auth.currentUser) {
            throw new Error('User not authenticated');
        }
        if (!auth.currentUser.email) {
            throw new Error('User email not found');
        }
        const credential = EmailAuthProvider.credential(auth.currentUser.email, password);
        await reauthenticateWithCredential(auth.currentUser, credential);
    };

    const signOut = async () => {
        await firebaseSignOut(auth);
        // Anonymouse users don't have dashboard access
        // TODO: For WebApp, we need to sign in anonymously
        // await signInAnonymously(auth);
        setUser(undefined);
        // TODO: Wtf is this? removed for now.
        // fetchUserNotifications(0);
    };

    const deleteUser = async () => {
        const response = await request({
            method: Method.DELETE,
            path: '/User',
        });

        switch (response.status) {
            case 200:
                const user = auth.currentUser;
                if (user) {
                    firebaseDeleteUser(user);
                    signOut();
                }
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        unsubscribe.current = onAuthStateChanged(
            auth,
            async firebaseUser => {
                console.log('onAuthStateChanged', firebaseUser);
                unsubscribe.current?.();
                try {
                    setUserIsAnonymous(auth.currentUser?.isAnonymous);
                    if (firebaseUser) {
                        let user = await getUser();
                        if (!user) {
                            await createUser();
                            user = await getUser();
                        }

                        setUser(user);
                    } else {
                        await signInAnonymously(auth);
                        setUserIsAnonymous(auth.currentUser?.isAnonymous);
                        await createUser();
                        const user = await getUser();
                        setUser(user);
                    }
                } catch (error) {
                    console.warn(error);
                    setUserError(String(error));
                }
            },
            (error: Error) => {
                console.warn(error);
                setUserError(String(error));
            },
            () => {},
        );

        // auth.settings.appVerificationDisabledForTesting = true;
        setVerifier(
            new RecaptchaVerifier(auth, 'recaptcha', {
                size: 'invisible',
                callback: () => {},
            }),
        );
    }, []);

    // const userIsAnonymous = auth.currentUser?.isAnonymous;

    const fetchUserNotifications = async (offset: number = 0): Promise<PagedNotifications> => {
        const FBUserID = auth.currentUser?.uid;
        if (!FBUserID) {
            throw new Error('User is not authenticated');
        }
        try {
            const response = await request({
                method: Method.GET,
                path: `/Notifications?offset=${offset * 100}`,
            });

            const responseData = response.data;
            const parsedData = typeof responseData === 'string' ? JSON.parse(responseData) : responseData;
            return parsedData;
        } catch (error) {
            console.error('Error fetching notifications:', error);
            return {
                Results: {
                    Notifications: [],
                    countRead: 0,
                    countUnRead: 0,
                },
                NextOffset: 0,
                Count: 0,
                Pages: 0,
            };
        }
    };

    const notificationRead = async (
        readFlag: boolean,
        notificationID?: number,
        markAll?: boolean,
    ): Promise<UserNotification[]> => {
        try {
            const response = await request({
                method: Method.PUT,
                path: '/Notifications',
                data: { notificationID, readFlag },
            });

            const responseData = response.data;
            const updatedNotifications: UserNotification[] = responseData.Results.Notifications as UserNotification[];

            return updatedNotifications;
        } catch (error) {
            console.error('Error updating notifications:', error);
            return [];
        }
    };

    const notificationAllRead = async (
        readFlag: boolean,
        notificationID?: number,
        markAll?: boolean,
    ): Promise<UserNotification[]> => {
        // try {
        //     await request({
        //         method: 'PUT',
        //         path: '/Notifications',
        //         body: { markAll: true },
        //     });

        //     const refreshNotifications = await fetchUserNotifications(0);

        //     return refreshNotifications;

        // } catch (error) {
        //     console.error('Error updating notifications:', error);
        return [];
        // }
    };

    const requestTwoFactorAuthCode = async () => {
        try {
            await request({
                method: Method.PUT,
                path: '/User/request2FA',
            });
        } catch (error) {
            console.error(error);
        }
    };

    const [twoFactorAuthDialogProps, setTwoFactorAuthDialogProps, twoFactorAuthDialogPropsRef] =
        useCapturedStateWorkaround<TwoFactorAuthDialogProps>({
            open: false,
            title: '',
            phoneNumber: '',
            confirm: '',
            onComplete: () => {},
            onClose: () => {},
        });
    const showTwoFactorAuthForm: TwoFactorAuthParams = (title: string, phoneNumber: string, confirm: string) =>
        new Promise<string>((resolve, reject) => {
            setTwoFactorAuthDialogProps({
                open: true,
                title,
                phoneNumber,
                confirm,
                onComplete: code => {
                    resolve(code);
                    setTwoFactorAuthDialogProps({
                        ...twoFactorAuthDialogPropsRef.current,
                        open: false,
                    });
                },
                onClose: () => {
                    reject('The Two Factor Auth was cancelled.');
                    setTwoFactorAuthDialogProps({
                        ...twoFactorAuthDialogPropsRef.current,
                        open: false,
                    });
                },
            });
        });

    const isUserNameAvailable = async (userName: string): Promise<boolean> => {
        try {
            const response = await request({
                method: Method.GET,
                path: `/User/nameCheck/${userName}`,
            });
            const responseData = response.data as PagedResults<{
                Available: boolean;
            }>;
            return !!responseData.Results?.Available;
        } catch (error) {
            console.error('Error while checking username availablity:', error);
            return false;
        }
    };

    return (
        <UserContext.Provider
            value={{
                userIsAnonymous,
                user,
                userError,
                addProfileAvatar,
                removeProfileAvatar,
                deleteUser,
                editProfile,
                fetchUserNotifications,
                notificationAllRead,
                notificationRead,
                sendPasswordResetEmail,
                signInWithApple,
                signInWithGoogle,
                signInWithEmailAndPassword,
                signInWithPhoneNumber,
                signInWithVerificationCode,
                reauthenticateWithPassword,
                signOut,
                signUpWithEmailAndPassword,
                updatePassword,
                getUser,
                requestTwoFactorAuth: async (title: string, phoneNumber: string, confirm: string) => {
                    setShowLoading(true);
                    await requestTwoFactorAuthCode();
                    setShowLoading(false);

                    return showTwoFactorAuthForm(title, phoneNumber, confirm);
                },
            }}
        >
            {/* DO NOT MOVE THIS */}
            <div id="recaptcha"></div>
            {children}
            <TwoFactorAuthDialog {...twoFactorAuthDialogProps} />
        </UserContext.Provider>
    );
};

const UserContext = createContext<TUser | undefined>(undefined);

export default UserContext;
