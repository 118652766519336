import { FC, createContext, useState } from 'react';
import { initializeApp } from 'firebase/app';
import { fetchAndActivate, getRemoteConfig, getValue } from 'firebase/remote-config';
import { getPerformance, trace, PerformanceTrace } from 'firebase/performance';
import { getDownloadURL, getStorage, ref, uploadBytesResumable } from 'firebase/storage';
import useAuth from '../hooks/useAuth';
import { AbortablePromise } from '../util/AbortablePromise/AbortablePromise';
import { AbortError } from '../util/AbortablePromise/AbortError';

const firebaseConfig = require(`../config/${process.env.REACT_APP_FIREBASE_CONFIG}.json`);
const app = initializeApp(firebaseConfig);
const performance = getPerformance(app);
// const analytics = getAnalytics(app);

interface ProductStore {
    name: string;
    domains: string[];
    buyBtnLight: string;
    buyBtnDark: string;
}

export interface TFirebase {
    startTrace: (name: string) => string;
    stopTrace: (uuid: string) => void;
    upload: (
        file: File,
        path: string,
        onProgress: (progress: number) => void,
        abortController?: AbortController,
    ) => AbortablePromise<string>;
    productStores: ProductStore[];
}

const performanceTraces: Record<string, PerformanceTrace> = {};

interface Props {
    children: React.ReactNode;
}

export const FirebaseProvider: FC<Props> = ({ children }) => {
    const storage = getStorage();
    const [productStores, setProductStores] = useState<ProductStore[]>([]);

    // const { setShowWebApp, setShowAdmin, setShowDashboard } = useContext(EnvContext) as TEnv;

    useAuth(async () => {
        try {
            const remoteConfig = getRemoteConfig();
            remoteConfig.settings.minimumFetchIntervalMillis = 0; // parseInt(process.env.REACT_APP_FIREBASE_REMOTE_CONFIG_TTL ?? '3600');
            remoteConfig.settings.fetchTimeoutMillis = 1000;
            await fetchAndActivate(remoteConfig);

            // console.log('Firebase Config:', process.env.REACT_APP_FIREBASE_CONFIG);
            // console.log('remoteConfig', JSON.stringify(getAll(remoteConfig), null, 4));

            const productStoresConfig = getValue(remoteConfig, 'productStores').asString();
            const stores = JSON.parse(productStoresConfig);
            setProductStores(stores);

            // TODO: Remote Config never refreshes values, must be doing something wrong.
            // setShowWebApp(getBoolean(remoteConfig, "enableWebApp"));
            // setShowAdmin(getBoolean(remoteConfig, "enableWebAdmin"));
            // setShowDashboard(getBoolean(remoteConfig, "enableWebDashboard"));
        } catch (error) {
            console.warn(error);
        }
    }, []);

    const startTrace = (name: string): string => {
        const uuid = crypto.randomUUID();
        const performanceTrace = trace(performance, name);
        performanceTrace.start();
        performanceTraces[uuid] = performanceTrace;
        return uuid;
    };

    const stopTrace = (uuid: string): void => {
        const performanceTrace = performanceTraces[uuid];
        performanceTrace.stop();
        delete performanceTraces[uuid];
    };

    const upload = async (
        file: File,
        path: string,
        onProgress: (progress: number) => void,
        abortController?: AbortController,
    ): AbortablePromise<string> => {
        return new AbortablePromise<string>((resolve, reject, innerAbortController) => {
            const name = crypto.randomUUID();
            const extension = file.name.split('.').pop();
            const fileRef = ref(storage, `/${path}/${name}.${extension}`);
            const uploadTask = uploadBytesResumable(fileRef, file);
            const abort = () => {
                uploadTask.cancel();
            };

            innerAbortController?.signal.addEventListener('abort', abort);

            uploadTask.on(
                'state_changed',
                snapshot => {
                    onProgress?.(Math.floor((snapshot.bytesTransferred / snapshot.totalBytes) * 100));
                },
                error => {
                    console.error(error);
                    reject(error);
                },
                () => {
                    getDownloadURL(uploadTask.snapshot.ref)
                        .then((downloadUrl: string) => {
                            if (abortController?.signal.aborted) {
                                throw new AbortError();
                            }
                            console.log('downloadUrl', downloadUrl);
                            resolve(downloadUrl);
                        })
                        .catch(error => {
                            console.error(error);
                            reject(error);
                        })
                        .finally(() => {
                            innerAbortController?.signal.removeEventListener('abort', abort);
                        });
                },
            );
        }, abortController);
    };

    return (
        <FirebaseContext.Provider
            value={{
                startTrace,
                stopTrace,
                upload,
                productStores,
            }}
        >
            {children}
        </FirebaseContext.Provider>
    );
};

const FirebaseContext = createContext<TFirebase | undefined>(undefined);

export default FirebaseContext;
