import { ThemeOptions } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import { Color, purpleGradientCSS } from './Color';

// DO NOT USE SX IN HERE

declare module '@mui/material/Button' {
    interface ButtonPropsVariantOverrides {
        irdbText: true;
        irdbGradient: true;
        irdbGray: true;
        irdbRed: true;
        editProfileImageUpload: true;
        editIawUser: true;
        // TODO: This probably dark mode gray as it's the dark gray used on auth which is basically just dark mode
        irdbDarkGray: true;
        webNotificationClearAll: true;
        webTextButton: true;
        iawHeadButton: true;
    }
}

// TextField variants not supported: https://github.com/mui/material-ui/issues/37846
// declare module '@mui/material/TextField' {
//     interface TextFieldPropsVariantOverrides {
//         irdbAuth: true;
//     }
// }

declare module '@mui/material/styles' {
    interface TypographyVariants {
        mainFont6: React.CSSProperties;
        mainFont7: React.CSSProperties;
        mainFont8: React.CSSProperties;
        mainFont9: React.CSSProperties;

        headlineMedium: React.CSSProperties;
        faqFont1: React.CSSProperties;

        metaDataLabel: React.CSSProperties;
        metaDataValue: React.CSSProperties;

        webNavlink: React.CSSProperties;
        webProfileName: React.CSSProperties;
        webAccountType: React.CSSProperties;
        webTimeNotification: React.CSSProperties;
        webHistoryTitle: React.CSSProperties;
        webFont8: React.CSSProperties;

        footerTerms: React.CSSProperties;
        webHeadlineSmall: React.CSSProperties;
        notificationSettingInfo: React.CSSProperties;
        lineDataLabel: React.CSSProperties;
        proVerifyHeader: React.CSSProperties;
        proVerifyFont2: React.CSSProperties;
        webLabel: React.CSSProperties;
        webLabel2: React.CSSProperties;
        iawTable: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        mainFont6: React.CSSProperties;
        mainFont7: React.CSSProperties;
        mainFont8: React.CSSProperties;
        mainFont9: React.CSSProperties;

        headlineMedium: React.CSSProperties;
        faqFont1: React.CSSProperties;

        metaDataLabel: React.CSSProperties;
        metaDataValue: React.CSSProperties;

        webNavlink: React.CSSProperties;
        webProfileName: React.CSSProperties;
        webAccountType: React.CSSProperties;
        webTimeNotification: React.CSSProperties;
        webHistoryTitle: React.CSSProperties;
        webFont8: React.CSSProperties;

        footerTerms: React.CSSProperties;
        webHeadlineSmall: React.CSSProperties;
        notificationSettingInfo: React.CSSProperties;
        lineDataLabel: React.CSSProperties;
        proVerifyHeader: React.CSSProperties;
        proVerifyFont2: React.CSSProperties;
        webLabel: React.CSSProperties;
        webLabel2: React.CSSProperties;
        iawTable: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        mainFont6: true;
        mainFont7: true;
        mainFont8: true;
        mainFont9: true;

        headlineMedium: true;
        faqFont1: true;

        metaDataLabel: true;
        metaDataValue: true;

        webNavlink: true;
        webProfileName: true;
        webAccountType: true;
        webTimeNotification: true;
        webHistoryTitle: true;
        webFont8: true;

        footerTerms: true;
        webHeadlineSmall: true;
        notificationSettingInfo: true;
        lineDataLabel: true;
        proVerifyHeader: true;
        proVerifyFont2: true;
        webLabel: true;
        webLabel2: true;
        iawTable: true;
    }
}
declare module '@mui/material/ListItem' {
    interface ListItemButtonPropsVariantOverrides {
        webNavlink: true;
    }
}

export const getDesignTokens = (mode: PaletteMode): ThemeOptions => {
    const darkMode = mode === 'dark';
    return {
        palette: {
            mode,
            ...(darkMode ?
                {
                    primary: {
                        main: Color.Purple,
                    },
                    background: {
                        default: Color.DarkModeBlack,
                    },
                }
            :   {
                    primary: {
                        main: Color.DarkModeBlack,
                    },
                    background: {
                        default: Color.LightLavenderLightMode,
                    },
                }),
        },
        typography: {
            // mainFont6 is also the same as Headline/Large in figma
            mainFont6: {
                fontFamily: 'Nocturne Serif',
                fontSize: '2.5rem',
                fontWeight: 600,
                lineHeight: '3.5rem',
                letterSpacing: '-0.03em',
            },

            mainFont7: {
                color: '#E6E6E6',
                fontSize: '0.625rem',
                fontFamily: 'Nunito Sans',
                fontWeight: 400,
                textTransform: 'uppercase',
                letterSpacing: '0.09375rem',
                wordWrap: 'break-word',
            },
            mainFont8: {
                color: '#FFFFFF',
                fontFamily: 'Nocturne Serif',
                fontSize: '1.5rem',
                fontWeight: 600,
                lineHeight: '1.625rem',
                letterSpacing: '0em',
                textAlign: 'left',
            },

            mainFont9: {
                fontFamily: 'Nunito Sans',
                fontSize: '1.125rem',
                fontWeight: 600,
                lineHeight: '1.625rem',
                letterSpacing: '-0.03em',
            },

            headlineMedium: {
                alignSelf: 'stretch',
                fontFamily: 'Nocturne Serif',
                fontSize: '2rem',
                fontWeight: 400,
                lineHeight: '2rem',
                letterSpacing: '-0.03em',
            },

            faqFont1: {
                fontFamily: 'Nunito Sans',
                fontSize: '1.25rem',
                fontWeight: 400,
                lineHeight: '1.75rem',
                letterSpacing: '0.01em',
                textAlign: 'left',
            },

            metaDataLabel: {
                fontSize: '0.75rem',
                fontFamily: 'Nunito Sans',
                fontWeight: 400,
                letterSpacing: '0.09375rem',
                wordWrap: 'break-word',
                color: Color.PrimaryDarkGrayBlue,
            },
            metaDataValue: {
                fontFamily: 'Nunito Sans',
                fontSize: '16px',
                fontWeight: '600',
                lineHeight: '24px',
                letterSpacing: '0em',
            },

            webNavlink: {
                fontFamily: 'Nunito Sans',
                fontSize: '.95rem',
                fontWeight: 400,
                lineHeight: '1.5rem',
                letterSpacing: '0.06em',
                textAlign: 'left',
                color: Color.LightGray,
            },
            webProfileName: {
                fontFamily: 'Nunito Sans',
                fontSize: '1rem',
                fontWeight: 400,
                lineHeight: '1.5rem',
                letterSpacing: '2px',
                wordSpacing: '4px',
            },
            webAccountType: {
                fontFamily: 'Nunito Sans',
                fontSize: '0.75rem',
                fontWeight: 400,
                lineHeight: '0.875rem',
            },
            webTimeNotification: {
                fontFamily: 'Nunito Sans',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '16px',
                letterSpacing: '0.15rem',
                textAlign: 'left',
                color: Color.PrimaryDarkGrayBlue,
            },
            webHistoryTitle: {
                fontFamily: 'Nunito Sans',
                fontSize: '20px',
                fontWeight: 400,
                lineHeight: '28px',
                letterSpacing: '0.01em',
                wordBreak: 'break-word',
            },
            footerTerms: {
                fontFamily: 'Nunito Sans',
                fontSize: '.75rem',
                fontWeight: 200,
                color: Color.TermsGray,
            },
            webFont8: {
                fontFamily: 'Nunito Sans',
                fontSize: '12px',
                fontWeight: 400,
                lineHeight: '16px',
                letterSpacing: '0.15em',
                textAlign: 'left',
            },
            webHeadlineSmall: {
                fontFamily: 'Nocturne Serif',
                fontSize: '24px',
                fontWeight: 400,
                lineHeight: '26px',
                letterSpacing: '0em',
                textAlign: 'left',
            },
            notificationSettingInfo: {
                fontFamily: 'Nunito Sans',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0em',
                textAlign: 'left',
            },
            lineDataLabel: {
                fontFamily: 'Nunito Sans',
                fontSize: '14px',
                fontWeight: 400,
                lineHeight: '19px',
                letterSpacing: '0.15em',
                textAlign: 'left',
            },
            proVerifyHeader: {
                fontFamily: 'Nocturne Serif',
                fontSize: '32px',
                fontWeight: 600,
                lineHeight: '40px',
                letterSpacing: '-.03em',
            },
            proVerifyFont2: {
                fontFamily: 'Nunito Sans',
                fontSize: '16px',
                fontWeight: 400,
                lineHeight: '21px',
                letterSpacing: '0.15em',
                textAlign: 'left',
            },
            webLabel: {
                fontFamily: 'Nunito Sans',
                fontSize: '10px',
                fontWeight: 400,
                letterSpacing: '.15',
                lineHeight: '20px',
            },
            webLabel2: {
                fontFamily: 'Nunito Sans',
                fontSize: '20px',
                fontWeight: 600,
                lineHeight: '28px',
                letterSpacing: '0.01em',
                textAlign: 'left',
            },
            iawTable: {
                flex: 1,
                textAlign: 'left',
                fontFamily: 'Nunito Sans',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '24px',
                letterSpacing: '0em',
            },
        },
        components: {
            MuiCssBaseline: {
                styleOverrides: {
                    body: {
                        // TODO: Why does this not apply? using App.css instead.
                        // overflow: { xs: 'hidden', sm: 'auto' },
                        backgroundColor: Color.DarkModeBlack,
                    },
                },
            },
            MuiTypography: {
                defaultProps: {
                    variantMapping: {
                        mainFont7: 'h1',
                        mainFont6: 'h1',
                        mainFont9: 'h1',

                        faqFont1: 'p',
                        metaDataLabel: 'p',
                        metaDataValue: 'p',
                        webAccountType: 'p',
                        webNavlink: 'p',
                        webTimeNotification: 'p',
                        webProfileName: 'p',
                        webHistoryTitle: 'p',
                        footerTerms: 'p',
                        webFont8: 'p',
                        webHeadlineSmall: 'p',
                        notificationSettingInfo: 'p',
                        lineDataLabel: 'p',
                        proVerifyHeader: 'p',
                        proVerifyFont2: 'p',
                        webLabel: 'p',
                        webLabel2: 'p',
                        iawTable: 'p',
                    },
                },
            },
            MuiButton: {
                variants: [
                    {
                        props: { variant: 'irdbText' },
                        style: {
                            color: Color.Purple,
                            fontSize: '1.125rem',
                            fontFamily: 'Nunito Sans',
                            // textTransform: 'uppercase',
                            lineHeight: '1.625rem',
                            letterSpacing: '0.05rem',
                            wordWrap: 'break-word',
                            '&:hover': {
                                color: 'white',
                            },
                        },
                    },
                    {
                        props: { variant: 'irdbGradient' },
                        style: {
                            background: purpleGradientCSS,
                            // width: '100%',
                            height: 'auto',
                            paddingY: '0.125rem',
                            paddingX: '0.25rem',
                            borderRadius: '1.8125rem',
                            gap: '0.75rem',
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                            fontSize: '1.125rem',
                            fontFamily: 'Nunito Sans',
                            textTransform: 'none',
                            '&[disabled]': {
                                color: '#fff',
                                opacity: 0.6,
                            },
                        },
                    },
                    {
                        props: { variant: 'irdbGray' },
                        style: {
                            backgroundColor: '#E8E8F2',
                            // width: '100%',
                            height: 'auto',
                            paddingY: '0.125rem',
                            paddingX: '0.25rem',
                            borderRadius: '1.8125rem',
                            gap: '0.75rem',
                            color: Color.Black,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                            fontFamily: 'Nunito Sans',
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '26px',
                            letterSpacing: '0em',
                            textTransform: 'none',
                        },
                    },
                    {
                        props: { variant: 'irdbRed' },
                        style: {
                            backgroundColor: '#BA2662',
                            // width: '100%',
                            height: 'auto',
                            paddingY: '0.125rem',
                            paddingX: '0.25rem',
                            borderRadius: '1.8125rem',
                            gap: '0.75rem',
                            color: Color.White,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',

                            fontFamily: 'Nunito Sans',
                            fontSize: '18px',
                            fontWeight: 600,
                            lineHeight: '26px',
                            letterSpacing: '0em',
                            textTransform: 'none',
                        },
                    },
                    {
                        props: { variant: 'irdbDarkGray' },
                        style: {
                            backgroundColor: '#2D2E36',
                            width: '100%',
                            height: 'auto',
                            paddingY: '0.125rem',
                            paddingX: '0.25rem',
                            borderRadius: '1.8125rem',
                            gap: '0.75rem',
                            color: '#fff',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            fontSize: '1.125rem',
                            fontFamily: 'Nunito Sans',
                        },
                    },
                    {
                        props: { variant: 'editProfileImageUpload' },
                        style: {
                            borderRadius: '17px',
                            background: darkMode ? Color.LightLavenderDarkMode : Color.LightLavenderLightMode,
                            backdropFilter: 'blur(12px)',
                            WebkitBackdropFilter: 'blur(12px)',
                            fontFamily: 'Nunito Sans',
                            fontSize: '12px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            lineHeight: '26px',
                            letterSpacing: '1.8px',
                            textTransform: 'uppercase',
                        },
                    },
                    {
                        props: { variant: 'editIawUser' },
                        style: {
                            borderRadius: '17px',
                            background: Color.TermsGray,
                            fontFamily: 'Nunito Sans',
                            fontSize: '14px',
                            fontStyle: 'normal',
                            fontWeight: 400,
                            letterSpacing: '1.8px',
                            textTransform: 'uppercase',
                            transition: '.05s',
                        },
                    },
                    {
                        props: { variant: 'webNotificationClearAll' },
                        style: {
                            fontFamily: 'Nunito Sans',
                            fontSize: '1rem',
                            fontWeight: 400,
                            textTransform: 'uppercase',
                        },
                    },
                    {
                        props: { variant: 'iawHeadButton' },
                        style: {
                            flex: 1,
                            justifyContent: 'left',
                            overflow: 'scroll',
                            fontFamily: 'Nunito Sans',
                            fontSize: '16px',
                            fontWeight: 400,
                            lineHeight: '21px',
                            letterSpacing: '0.15em',
                            textAlign: 'left',
                        },
                    },
                ],
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        '&.Mui-checked': {
                            color: Color.Purple,
                        },
                    },
                },
            },
            MuiDrawer: {
                styleOverrides: {
                    paper: {
                        // TODO: This was too broad
                        // This allows the rounded corners to show
                        // backgroundColor: darkMode ? Color.DarkModeBlack : Color.LightLavenderLightMode,
                        // color: darkMode ? Color.LightLavenderDarkMode : Color.DarkModeBlack,
                    },
                },
                // {
                //     props: { variant: 'webTextButton' },
                //     style: {
                //         fontFamily: 'Nunito Sans',
                //         fontSize: '16px',
                //         fontWeight: 400,
                //         lineHeight: '21px',
                //         letterSpacing: "15%"
                //     },
                // },
            },
            MuiTab: {
                styleOverrides: {
                    root: {
                        fontFamily: 'Nunito Sans',
                        fontSize: '16px',
                        fontWeight: 400,
                        lineHeight: '26px',
                        letterSpacing: '0.15em',
                        color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,

                        '&.Mui-selected': {
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                        },
                    },
                },
            },
        },
    };
};
