import { Stack, Typography } from '@mui/material';
import { useCallback, useContext } from 'react';
import { Color } from 'src/Color';
import FileDropArea from 'src/components/general/FileDropArea';
import ThemeContext, { TTheme } from 'src/contexts/ThemeContext';
import useAttendeeList from 'src/hooks/useAttendeeList';

export const Conferences = () => {
    const { darkMode } = useContext(ThemeContext) as TTheme;

    const { loadAttendees } = useAttendeeList();

    const onDrop = useCallback(
        async (files: File[]) => {
            loadAttendees(files[0]);
        },
        [loadAttendees],
    );

    return (
        <Stack
            direction="column"
            spacing={2}
            sx={{
                p: 2,
            }}
        >
            <FileDropArea
                dropzoneOptions={{
                    onDrop,
                    accept: { 'text/csv': ['.csv'] },
                }}
                dragInvalidText={null}
                dragValidText={null}
                className="dashboard-uploader"
            >
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        p: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                >
                    <i className="fa-light fa-images fa-2xl" style={{ color: Color.Purple }}></i>
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '20px',
                            fontWeight: 400,
                            lineHeight: '28px',
                            letterSpacing: '0.01em',
                            textAlign: 'left',
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                        }}
                    >
                        Drop a CSV of Attendees here
                    </Typography>
                </Stack>
            </FileDropArea>
        </Stack>
    );
};
