import { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MediaContext, {
    ImageOperation,
    TMedia,
    imageFromImageOperation,
    imageOperationFromImage,
} from '../../contexts/MediaContext';
import useAuth from '../../hooks/useAuth';
import Info from './Info';
import StackNav from '../StackNav';

const Image = () => {
    const { load } = useContext(MediaContext) as TMedia;

    const { id } = useParams();
    const [imageOperation, setImageOperation] = useState<ImageOperation<any>>();

    const navigate = useNavigate();

    useAuth(async () => {
        if (id) {
            try {
                const image = await load(id);
                setImageOperation(await imageOperationFromImage(image));
            } catch (error) {
                console.error(error);
            }
        }
    }, [id]);

    if (imageOperation) {
        return (
            <StackNav
                initialComponent={(pushToStack, popFromStack) => {
                    return (
                        <Info
                            pushToStack={pushToStack}
                            popFromStack={popFromStack}
                            image={imageOperation ? imageFromImageOperation(imageOperation) : undefined}
                            onClose={() => {
                                // You got here from a share link to a specific image, so load the homepage when you click the close button
                                navigate('/');
                                setImageOperation(undefined);
                            }}
                        />
                    );
                }}
            />
        );
    } else {
        return null;
    }
};

export default Image;
