import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, Stack, Typography } from '@mui/material';
import MediaContext, {
    Add,
    CancellablePromise,
    ImageOperation,
    Init,
    Query,
    RegistrationStatus,
    removeImageOperation,
    replaceImageOperation,
    TMedia,
    Upload,
} from '../../../contexts/MediaContext';
import { Operation, Type } from '../../../contexts/Operation';
import Pending from './Pending';
import Available from './Available';
import Adding from './Adding';
import Added from './Added';
import ThemeContext, { TTheme } from '../../../contexts/ThemeContext';
import { Color } from '../../../Color';
import MetaContext, { TMeta, validateMeta } from '../../../contexts/MetaContext';
import UserContext, { TUser } from '../../../contexts/UserContext';
import FeedbackContext, { TFeedback } from '../../../contexts/FeedbackContext';
import { adminIrcodeAccept, ircodeAccept } from 'src/util/reactDropzone';
import FileDropArea from '../../general/FileDropArea';
import calculateTimespan from '../../../util/timespan';
import UploaderProgressBar from './UploaderProgressBar';
import { useCapturedStateWorkaround } from '../../../hooks/useCapturedStateWorkaround';
import useOperationsTimeEstimation from '../../../hooks/useOperationsTimeEstimation';
import useBeforeUnloadConfirm from '../../../hooks/useBeforeUnloadConfirm';
import usePromiseQueue from '../../../hooks/usePromiseQueue';
import GeneralErrorMessages from '../../../constants/generalErrorMessages.json';
import useMetaErrors from '../../../hooks/metaErrors/useMetaErrors';

const getOperationProgress = (operation?: Operation<any>) => {
    if (!operation) {
        return 0;
    }
    switch (operation.type) {
        case Type.Prep:
            return 25;
        case Type.Upload:
            return (operation.Results?.progress ?? 50) as number;
        case Type.Foveate:
            return 50;
        case Type.Query:
        case Type.Add:
            // TODO: Queries take a long time, should at least try and give them some weight
            return 100;
        default:
            return 100;
    }
};

const findErrors = (results: PromiseSettledResult<any>[]) =>
    results.filter(r => r.status === 'rejected') as PromiseRejectedResult[];

const getRejectReason = (result: PromiseRejectedResult): string => {
    if (!result.reason) {
        return 'Unknown error';
    }
    if (result.reason instanceof Error) {
        return result.reason.message.trim();
    }
    return result.reason.toString().trim();
};

const flattenRejectReasons = (errors: PromiseRejectedResult[]): PromiseRejectedResult[] => {
    return errors.reduce<PromiseRejectedResult[]>((prev, curr) => {
        if (Array.isArray(curr.reason)) {
            return [...prev, ...flattenRejectReasons(curr.reason)];
        }
        return [...prev, curr];
    }, []);
};

// TODO: Figure out a better way to handle this
// counts the number of times an error appears in the list of errors and returns a string with all the errors and their counts
// e.g. "Error A (2 files), Error B (1 file)"
const getErrorString = (errors: PromiseRejectedResult[]) =>
    errors
        .map(err => getRejectReason(err))
        .reduce<[string, number][]>((prev, curr) => {
            const index = prev.findIndex(([error]) => error === curr);
            if (index === -1) {
                return [...prev, [curr, 1]];
            }
            prev[index][1]++;
            return prev;
        }, [])
        .map(([error, count]) => `${error} ${count > 1 ? `(${count} files)` : ''}`)
        .join(', ');

const getErrorMessage = (errors: PromiseRejectedResult[]) => {
    return getErrorString(
        flattenRejectReasons(errors).filter(err => getRejectReason(err) !== GeneralErrorMessages.PromiseAborted),
    );
};

interface Props {
    onStart: () => void;
    onAdd?: (imageId: string) => Promise<void>;
    onComplete: () => void;
}

export default function BulkUploader({ onStart, onAdd, onComplete }: Props) {
    const { darkMode } = useContext(ThemeContext) as TTheme;
    const { notify } = useContext(FeedbackContext) as TFeedback;
    const { user } = useContext(UserContext) as TUser;
    const { init, prep, foveate, upload, query, add } = useContext(MediaContext) as TMedia;
    const { save } = useContext(MetaContext) as TMeta;

    const [files, setFiles] = useState<File[]>();
    const [imageOperations, setImageOperations] = useState<ImageOperation<any>[]>([]);
    const cancelHandlers = useRef<Record<string, Function>>({});
    const [processingCount, setProcessingCount] = useState(0);
    const [processedCount, setProcessedCount] = useState(1);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isCanceling, setIsCanceling] = useState(false);
    const [progress, setProgress, progressRef] = useCapturedStateWorkaround(0);
    const { enable: enableUnloadConfirm, disable: disableUnloadConfirm } = useBeforeUnloadConfirm();
    const { timeEstimation, isEstimatingTime, addOperations, startEstimation, advanceEstimation, completeEstimation } =
        useOperationsTimeEstimation();
    const { addToQueue, clearQueue } = usePromiseQueue(() => {
        // replaces the 'processFiles' call's 'finally' block
        console.log('All finished');
        setProcessedCount(1);
        setProcessingCount(0);
        setIsProcessing(false);
        setIsCanceling(false);
        completeEstimation();
        clearQueue();
    });

    const setMetaErrors = useMetaErrors.use.setErrors();
    const fileDropRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (processingCount === 0 || imageOperations.length === 0) {
            return;
        }
        if (processedCount >= processingCount && processingCount !== 1) {
            setProgress(100);
            return;
        }
        const currentProcessing = imageOperations[processedCount - 1];
        if (!currentProcessing) {
            return;
        }
        const currentProgress = getOperationProgress(currentProcessing.operation);
        const progressPerOperation = 100 / processingCount;
        const newProgress =
            processedCount * progressPerOperation - progressPerOperation + currentProgress / processingCount;
        if (newProgress > progressRef.current) {
            setProgress(newProgress);
        }
    }, [imageOperations, processingCount, processedCount]);

    useEffect(() => {
        if (processingCount === 0) {
            setProgress(0);
        }
    }, [processingCount]);

    useEffect(() => {
        if (imageOperations.length === 0) {
            onComplete();
            setIsCanceling(false);
        } else {
            onStart();
        }
    }, [imageOperations]);

    const updateCancelHandlers = (o: CancellablePromise<any>) => {
        cancelHandlers.current[o.id] = o.cancel;
    };

    const removeCancelHandler = (o: ImageOperation<any>) => {
        delete cancelHandlers.current[o.id];
    };

    useEffect(() => {
        if (!files || files.length === 0) {
            return;
        }

        const filesAmount = files.length;
        setProcessingCount(count => count + filesAmount);
        const isEstimating = isEstimatingTime.current;

        onStart();
        // TODO: This entire graph needs to be pushed back into the context(Lior's note: or a hook) with just callbacks for updates / cancel handles
        const handleInitOperation = async (imageOperation: ImageOperation<Init>) => {
            try {
                if (isEstimating) {
                    addOperations(filesAmount);
                } else {
                    startEstimation(filesAmount);
                }
                const p = prep(imageOperation, true);
                updateCancelHandlers(p);
                const prepped = await p.promise;
                replaceImageOperation(prepped, setImageOperations);

                const f = foveate(prepped);
                updateCancelHandlers(f);
                const foveated = await f.promise;
                replaceImageOperation(foveated, setImageOperations);

                const q = query(foveated, (progress: ImageOperation<Query>) => {
                    replaceImageOperation(progress, setImageOperations);
                });
                updateCancelHandlers(q);
                const queried = await q.promise;
                replaceImageOperation(queried, setImageOperations);
                removeCancelHandler(queried);

                console.log('One finished');
            } catch (error) {
                removeImageOperation(imageOperation, setImageOperations);
                throw error;
            }
        };

        const handleFile = async (file: File) => {
            const i = init(file, (progress: ImageOperation<Init>) => {
                replaceImageOperation(progress, setImageOperations);
            });
            updateCancelHandlers(i);
            // continue;
            const initialized = await i.promise;
            const results = await Promise.allSettled(initialized.map(handleInitOperation));
            setProcessedCount(count => count + 1);
            advanceEstimation();
            const errors = findErrors(results);
            if (errors.length) {
                throw errors;
            }
            return results;
        };

        const processFiles = async (files: File[]) => {
            const results = await Promise.allSettled(files.map(handleFile));
            const errors = flattenRejectReasons(findErrors(results)).filter(
                err => getRejectReason(err) !== GeneralErrorMessages.PromiseAborted,
            );
            if (errors.length) {
                throw errors;
            }
        };
        const filesRef = [...files];
        addToQueue(
            processFiles(filesRef).catch(async (errors: PromiseRejectedResult[]) => {
                const errorMessage = getErrorMessage(errors);
                if (errorMessage) {
                    await notify('Upload Error', errorMessage);
                }
                // If all files failed, clear image operations
                if (errors.length === filesAmount) {
                    setImageOperations([]);
                }
            }),
        );

        setFiles([]);
    }, [files]);

    const onDrop = useCallback(async (files: any[]) => {
        // TODO: Pull from user records
        const maxSimultaneousUploads = 10;

        // TODO: Maybe block further uploads until this is done?
        // TODO: Maybe only check for new files here...

        // TODO: Check if file is already in the system
        setFiles(files);
    }, []);

    const registerSelectedImages = async () => {
        // validate availableIrcodes metaData
        let hasErrors = false;
        const allIrcodes = [...availableIrcodes, ...unavailableIrcodes];
        const validatedAvailable: ImageOperation<any>[] = [];
        for (let i = 0; i < allIrcodes.length; i++) {
            const imageOperation = allIrcodes[i];
            const { bulkOperation } = imageOperation;
            if (!bulkOperation) {
                continue;
            }
            const meta = bulkOperation.meta;
            if (!meta) {
                continue;
            }
            const { results, errors } = validateMeta(meta);
            if (errors) {
                setMetaErrors(imageOperation.id, errors);
                hasErrors = true;
            } else {
                validatedAvailable.push({
                    ...imageOperation,
                    bulkOperation: {
                        ...bulkOperation,
                        meta: results,
                    },
                });
            }
        }
        if (hasErrors) {
            await notify('Information missing or invalid', 'Please fill in all required fields');
            return;
        }
        try {
            if (fileDropRef.current) {
                fileDropRef.current.scrollIntoView({ block: 'start' });
            }
            const filteredOperations = validatedAvailable.filter(i => i.bulkOperation?.selected === true);
            setProcessingCount(filteredOperations.length);
            startEstimation(filteredOperations.length);
            window.scrollTo({ top: 0, behavior: 'smooth' });
            const added = await Promise.allSettled(
                filteredOperations.map(async i => {
                    try {
                        const u = upload(i, (progress: ImageOperation<Upload>) => {
                            replaceImageOperation(progress, setImageOperations);
                        });
                        updateCancelHandlers(u);
                        const uploaded = await u.promise;
                        replaceImageOperation(uploaded, setImageOperations);

                        const a = add(uploaded, uploaded.bulkOperation?.status, (added: ImageOperation<Add>) => {
                            replaceImageOperation(added, setImageOperations);
                        });
                        updateCancelHandlers(a);
                        const added = await a.promise;
                        replaceImageOperation(uploaded, setImageOperations);

                        removeCancelHandler(added);
                        // The operation cannot be cancelled from here on

                        await save(added.operation.Results!.Image.imageID, added.bulkOperation?.meta ?? [], status => {
                            console.log('status', status);
                            const newAdded = structuredClone(added);
                            newAdded.operation.status = status;
                            replaceImageOperation(newAdded, setImageOperations);
                        });

                        // TODO: I don't like this
                        const newAdded = structuredClone(added);
                        newAdded.operation.type = Type.Completed;
                        replaceImageOperation(newAdded, setImageOperations);

                        if (onAdd) {
                            await onAdd(newAdded.operation.Results!.Image.imageID);
                        }
                        return added;
                    } catch (error) {
                        removeCancelHandler(i);
                        removeImageOperation(i, setImageOperations);
                        throw error;
                    } finally {
                        setProcessedCount(count => Math.min(filteredOperations.length, count + 1));
                        advanceEstimation();
                    }
                }),
            );

            // console.log('added', added);
            // console.log('All Adds complete');
            const errors = findErrors(added);
            if (errors.length) {
                const errorMessage = getErrorMessage(errors);
                if (errorMessage) {
                    await notify('Upload Error', errorMessage);
                }
            }
        } catch (error) {
            console.error(error);
        } finally {
            setProcessedCount(1);
            setProcessingCount(0);
            setIsProcessing(false);
            setIsCanceling(false);
            completeEstimation();
        }
    };

    const handleCancel = (reset = false) => {
        setIsCanceling(true);
        console.log('cancelHandlers.current', cancelHandlers.current);
        Object.values(cancelHandlers.current).forEach(cancel => {
            cancel();
        });
        if (reset) {
            setImageOperations([]);
        }
    };

    type IRCodes = {
        [key in
            | 'pendingIrcodes'
            | 'availableIrcodes'
            | 'addingIrcodes'
            | 'addedIrcodes'
            | 'unavailableIrcodes']: ImageOperation<any>[];
    };

    const { pendingIrcodes, availableIrcodes, addingIrcodes, addedIrcodes, unavailableIrcodes } = useMemo(
        () =>
            imageOperations.reduce<IRCodes>(
                (prev, curr) => {
                    if (curr.type !== 'image') {
                        return prev;
                    }
                    switch (curr.status) {
                        case RegistrationStatus.Pending:
                            prev.pendingIrcodes.push(curr);
                            break;
                        case RegistrationStatus.Available:
                            prev.availableIrcodes.push(curr);
                            break;
                        case RegistrationStatus.Adding:
                            prev.addingIrcodes.push(curr);
                            break;
                        case RegistrationStatus.Added:
                            prev.addedIrcodes.push(curr);
                            break;
                        case RegistrationStatus.Unavailable:
                            prev.unavailableIrcodes.push(curr);
                            break;
                    }
                    return prev;
                },
                {
                    pendingIrcodes: [],
                    availableIrcodes: [],
                    addingIrcodes: [],
                    addedIrcodes: [],
                    unavailableIrcodes: [],
                },
            ),
        [imageOperations],
    );

    const isAddingCancelDisabled = useMemo(
        () => !!addingIrcodes.length && addingIrcodes.findIndex(i => i.operation.type === Type.Upload) === -1,
        [addingIrcodes],
    );

    const isCancelProgressEnabled =
        isCanceling &&
        !isAddingCancelDisabled &&
        // This is to prevent the canceling progress bar from popping up after resolving the last operation
        imageOperations.length !== addedIrcodes.length + unavailableIrcodes.length;

    const cancelButtonText =
        !isAddingCancelDisabled && (addedIrcodes.length || unavailableIrcodes.length) && !availableIrcodes.length ?
            'Return to My IRCODES'
        :   'Cancel';

    useEffect(() => {
        if (isProcessing || pendingIrcodes.length || availableIrcodes.length || addingIrcodes.length) {
            enableUnloadConfirm();
        } else {
            disableUnloadConfirm();
        }
    }, [isProcessing, pendingIrcodes, availableIrcodes, addingIrcodes]);

    const timeEstimationTimespan = useMemo(
        () =>
            processingCount < 3 ?
                // If we have less than 3 operations, we can't estimate time
                calculateTimespan((pendingIrcodes.length || addingIrcodes.length) * 2000)
            : timeEstimation ? calculateTimespan(timeEstimation)
            : null,
        [timeEstimation, processingCount, pendingIrcodes.length, addingIrcodes.length],
    );

    const isUploading =
        !!files?.length && !pendingIrcodes.length && !availableIrcodes.length && !unavailableIrcodes.length;

    return (
        <Stack direction="column" spacing={4}>
            <FileDropArea
                dropzoneOptions={{
                    onDrop,
                    accept: !user?.internalAdmin ? ircodeAccept : adminIrcodeAccept,
                }}
                dragInvalidText={null}
                dragValidText={null}
                className="dashboard-uploader"
            >
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{
                        p: 4,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        cursor: 'pointer',
                    }}
                    ref={fileDropRef}
                >
                    <i className="fa-light fa-images fa-2xl" style={{ color: Color.Purple }}></i>
                    <Typography
                        sx={{
                            fontFamily: 'Nunito Sans',
                            fontSize: '20px',
                            fontWeight: 400,
                            lineHeight: '28px',
                            letterSpacing: '0.01em',
                            textAlign: 'left',
                            color: darkMode ? Color.White : Color.PrimaryDarkGrayBlue,
                        }}
                    >
                        Drop your images here or click to upload
                    </Typography>
                </Stack>
            </FileDropArea>

            {(isCancelProgressEnabled || isUploading) && (
                <UploaderProgressBar
                    label={`${isCancelProgressEnabled ? 'Canceling' : 'Uploading'}...`}
                    darkMode={darkMode}
                    progress={null}
                />
            )}
            {!isCanceling && imageOperations.length > 0 && processingCount > 0 && (
                <>
                    {pendingIrcodes.length > 0 && (
                        <UploaderProgressBar
                            remainingTime={timeEstimationTimespan}
                            isEstimatingTime={!timeEstimationTimespan}
                            label={`Querying ${processedCount}/${processingCount}`}
                            darkMode={darkMode}
                            progress={progress}
                            onCancel={() => handleCancel()}
                        />
                    )}
                    {addingIrcodes.length > 0 && (
                        <UploaderProgressBar
                            remainingTime={timeEstimationTimespan}
                            isEstimatingTime={!timeEstimationTimespan}
                            label={`Saving ${processedCount}/${processingCount}`}
                            darkMode={darkMode}
                            progress={progress}
                            onCancel={() => handleCancel()}
                            cancelDisabled={isAddingCancelDisabled}
                        />
                    )}
                </>
            )}

            <Pending key="pending" imageOperations={pendingIrcodes} />

            <Available
                key="available"
                imageOperations={availableIrcodes}
                replaceImageOperation={imageOperation => replaceImageOperation(imageOperation, setImageOperations)}
                removeImageOperation={imageOperation => removeImageOperation(imageOperation, setImageOperations)}
                registerSelectedImages={registerSelectedImages}
                registerDisabled={isProcessing || !!pendingIrcodes.length || !!addingIrcodes.length}
            />

            <Adding key="adding" imageOperations={addingIrcodes} />

            <Added key="added" imageOperations={addedIrcodes} />

            {/* These are not available, but we're doing it anyway. */}
            {/* <Unavailable key="unavailable" imageOperations={unavailableIrcodes} /> */}

            <Available
                key="unavailable"
                imageOperations={unavailableIrcodes}
                replaceImageOperation={imageOperation => replaceImageOperation(imageOperation, setImageOperations)}
                removeImageOperation={imageOperation => removeImageOperation(imageOperation, setImageOperations)}
                registerSelectedImages={registerSelectedImages}
                registerDisabled={isProcessing || !!pendingIrcodes.length || !!addingIrcodes.length}
            />
            {imageOperations.length > 0 && (
                <Button disabled={isAddingCancelDisabled} variant="irdbText" onClick={() => handleCancel(true)}>
                    {cancelButtonText}
                    <i className="fa-light fa-x" style={{ marginLeft: 4 }}></i>
                </Button>
            )}
        </Stack>
    );
}
