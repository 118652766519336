import { Button, Stack } from '@mui/material';
import { useCallback, useContext } from 'react';
import FeedbackContext, { TFeedback } from '../../contexts/FeedbackContext';
import { metaFieldForMetaType, metaIncludes } from '../../contexts/MetaContext';
import { MetaField } from '../../types/MetaField';
import { Image } from '../../contexts/MediaContext';
import { MetaType, MetaContent } from '../../types/MetaTypes';
import { CardTypeForm } from './CardType';
import { CustomForm, Custom } from './Custom';
import { Description, DescriptionForm } from './Description';
import { Link, LinkForm } from './Link';
import { TagsForm, Tags } from './Tags';
import { Title, TitleForm } from './Title';
import PrivateIndicator from './PrivateIndicator';
import { JobTitle, JobTitleForm } from './JobTitle';
import { Company, CompanyForm } from './Company';
import { Email } from './Email';
import { Phone } from './Phone';
import { LastNameForm } from './LastName';
import { FirstNameForm } from './FirstName';
import { apiBaseURL } from 'src/hooks/useApi';
import isMobileJs from 'ismobilejs';

export default function ContactCardStack({
    image,
    meta,
    isEditing,
    editingTypes = [
        MetaType.CardType,
        MetaType.Title,
        MetaType.FirstName,
        MetaType.LastName,
        MetaType.Company,
        MetaType.JobTitle,
        MetaType.Email,
        MetaType.Phone,
        MetaType.Link,
    ],
    hiddenTypes = [],
    disabledTypes = [],
    onChange: onChangeProp,
}: {
    image: Image;
    meta: MetaField[];
    isEditing: boolean;
    editingTypes?: MetaType[];
    hiddenTypes?: MetaType[];
    disabledTypes?: MetaType[];
    onChange: (metaType: MetaType, metaContent: MetaContent) => void;
}) {
    const { confirm } = useContext(FeedbackContext) as TFeedback;

    const onChange = useCallback(
        (metaType: MetaType, metaContent: MetaContent) => {
            onChangeProp(metaType, metaContent);

            // TODO: Need to fix this. It's not working.
            // if (metaType === MetaType.FirstName || metaType === MetaType.LastName) {
            //     console.log('Also change title');
            //     setTimeout(() => {
            //         onChangeProp(MetaType.Title, {
            //             title: `${metaFieldForMetaType(meta, MetaType.FirstName)?.metaContent.firstName} ${metaFieldForMetaType(meta, MetaType.LastName)?.metaContent.lastName}`,
            //         } as TTitle);
            //     }, 10);
            //     // return;
            // }
        },
        [onChangeProp, meta],
    );

    if (isEditing) {
        return (
            <Stack direction="column" spacing={2}>
                {editingTypes.includes(MetaType.CardType) && (
                    <CardTypeForm
                        metaField={metaFieldForMetaType(meta, MetaType.CardType)}
                        hidden={hiddenTypes.includes(MetaType.CardType)}
                        onChange={async (metaContent: MetaContent) => {
                            if (
                                await confirm({
                                    title: 'Are your sure?',
                                    message: 'Some data may be lost',
                                    yes: 'Switch Templates',
                                    no: 'Cancel',
                                })
                            ) {
                                onChange(MetaType.CardType, metaContent);
                            }
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.Title) && (
                    <TitleForm
                        metaField={metaFieldForMetaType(meta, MetaType.Title)}
                        hidden={hiddenTypes.includes(MetaType.Title)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Title, metaContent);
                        }}
                    />
                )}

                {editingTypes.includes(MetaType.FirstName) && (
                    <FirstNameForm
                        metaField={metaFieldForMetaType(meta, MetaType.FirstName)}
                        disabled={disabledTypes.includes(MetaType.FirstName)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.FirstName, metaContent);
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.LastName) && (
                    <LastNameForm
                        metaField={metaFieldForMetaType(meta, MetaType.LastName)}
                        disabled={disabledTypes.includes(MetaType.LastName)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.LastName, metaContent);
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.Company) && (
                    <CompanyForm
                        metaField={metaFieldForMetaType(meta, MetaType.Company)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Company, metaContent);
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.JobTitle) && (
                    <JobTitleForm
                        metaField={metaFieldForMetaType(meta, MetaType.JobTitle)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.JobTitle, metaContent);
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.Link) && (
                    <LinkForm
                        metaField={metaFieldForMetaType(meta, MetaType.Link)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Link, metaContent);
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.Description) && (
                    <DescriptionForm
                        metaField={metaFieldForMetaType(meta, MetaType.Description)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Description, metaContent);
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.Tags) && (
                    <TagsForm
                        metaField={metaFieldForMetaType(meta, MetaType.Tags)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Tags, metaContent);
                        }}
                    />
                )}
                {editingTypes.includes(MetaType.Custom) && (
                    <CustomForm
                        metaField={metaFieldForMetaType(meta, MetaType.Custom)}
                        onChange={(metaContent: MetaContent) => {
                            onChange(MetaType.Custom, metaContent);
                        }}
                    />
                )}
            </Stack>
        );
    } else {
        return (
            <Stack spacing={2}>
                <Stack
                // In WebApp, the meta components have the spacing. Maybe fix.
                // spacing={2}
                >
                    <Title image={image} metaField={metaFieldForMetaType(meta, MetaType.Title)} />
                    <JobTitle image={image} metaField={metaFieldForMetaType(meta, MetaType.JobTitle)} />
                    <Company image={image} metaField={metaFieldForMetaType(meta, MetaType.Company)} />
                    <PrivateIndicator mt={4} image={image} />
                    <Link image={image} metaField={metaFieldForMetaType(meta, MetaType.Link)} />
                    <Email image={image} metaField={metaFieldForMetaType(meta, MetaType.Email)} />
                    <Phone image={image} metaField={metaFieldForMetaType(meta, MetaType.Phone)} />
                </Stack>
                {metaIncludes(meta, [MetaType.Description, MetaType.Tags, MetaType.Custom]) && (
                    <Stack
                    // In WebApp, the meta components have the spacing. Maybe fix.
                    // spacing={2}
                    >
                        <Description image={image} metaField={metaFieldForMetaType(meta, MetaType.Description)} />
                        <Tags image={image} metaField={metaFieldForMetaType(meta, MetaType.Tags)} />
                        <Custom image={image} metaField={metaFieldForMetaType(meta, MetaType.Custom)} />
                    </Stack>
                )}
                <Stack>
                    {!(navigator.userAgent.includes('capacitor-v5') && isMobileJs().android) && ( // hide the button in the Brandweek Android app - won't work there
                        <Button
                            id="button"
                            variant="irdbGradient"
                            sx={{
                                height: '56px',
                                mt: 2,
                                mx: 3,
                            }}
                            href={`${apiBaseURL}/Images/vcard/${image.imageID}`}
                            target="_blank"
                        >
                            {isMobileJs().apple.device ? 'Save to Contacts' : 'Save Contact File'}
                        </Button>
                    )}
                </Stack>
            </Stack>
        );
    }
}
